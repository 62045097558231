import {
	Preferences as CapacitorPreferences,
	type ConfigureOptions,
	type GetOptions,
	type RemoveOptions,
	type SetOptions,
} from "@capacitor/preferences";

export const Preferences = (function () {
	async function configure(group?: ConfigureOptions["group"]) {
		return await CapacitorPreferences.configure({ group });
	}

	async function get(key: GetOptions["key"]) {
		return await CapacitorPreferences.get({ key });
	}

	async function set(options: SetOptions) {
		return await CapacitorPreferences.set(options);
	}

	async function remove(key: RemoveOptions["key"]) {
		return await CapacitorPreferences.remove({ key });
	}

	async function clear() {
		return await CapacitorPreferences.clear();
	}

	async function keys() {
		return await CapacitorPreferences.keys();
	}

	async function migrate() {
		return await CapacitorPreferences.migrate();
	}

	async function removeOld() {
		return await CapacitorPreferences.removeOld();
	}

	return {
		...CapacitorPreferences,
		configure,
		get,
		set,
		remove,
		clear,
		keys,
		migrate,
		removeOld,
	};
})();

export type * from "@capacitor/preferences";
