import { IonIcon, IonPage, useIonRouter } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import { type EmailVerificationCodeDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { arrowPathIcon, envelopeIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function AuthForgotPassword() {
	const posthog = usePostHog();
	const { t, i18n } = useTranslation();
	const state = useAuthFlowStore();
	const router = useIonRouter();

	const mutationSendCode = useExtractedMutation({
		mutationKey: ["auth", "password-reset", "send"],
		fn: (variables: EmailVerificationCodeDto) =>
			backendService.auth.authControllerSendPasswordResetVerificationCode(variables, {
				format: "text",
			}),
	});

	const form = useZodForm({
		defaultValues: { email: state.email },
		onSubmit: ({ value, formApi }) =>
			mutationSendCode.mutateAsync(value, {
				onSuccess: (_data, variables) => {
					posthog.capture("User Forgot Password Send", { success: true });
					useAuthFlowStore.setState(variables);
					router.push(Routes.AuthForgotPasswordVerify(), "forward");
				},
				onError: (error) => {
					posthog.capture("User Forgot Password Send", { success: false });

					let message: string;

					if (error instanceof Error) {
						message = error.message;
					} else {
						message = getErrorText({
							language: i18n.language,
							errors: error.message.map((message) => {
								switch (message) {
									case "Email doesn't exist!":
										return t("fields.email.errors.unused");
									default:
										return message;
								}
							}),
						});
					}

					formApi.setFieldMeta("email", (current) => ({
						...current,
						errorMap: {
							onSubmit: message,
						},
					}));
				},
			}),
	});

	return (
		<IonPage>
			<AuthHeader parts={{ backButton: { defaultHref: Routes.AuthSignIn() } }} />
			<AuthContent>
				<TitleIcon icon={arrowPathIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.forgot-password.title") },
						subtitle: { children: t("page.auth.forgot-password.subtitle") },
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("blur");
						void form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						validators={{
							onBlur: z.string().email(t("fields.email.errors.invalid")),
						}}
						children={(field) => (
							<FormInput
								field={field}
								type="email"
								autofocusOnIonViewDidEnter
								label={t("fields.email.label")}
								autocomplete="email"
								inputMode="email"
								placeholder={t("fields.email.placeholder")}
							>
								<IonIcon slot="start" icon={envelopeIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton
								disabled={!canSubmit || mutationSendCode.isSuccess}
								isSubmitting={isSubmitting}
							>
								{t("page.auth.forgot-password.action.primary")}
							</AuthSubmitButton>
						)}
					/>
				</form>
			</AuthContent>
		</IonPage>
	);
}
