import { type UseQueryOptions } from "@tanstack/react-query";
import { Share } from "@/lib/capacitor/Share";

export const queryKey_canShare = ["@capacitor/share", "canShare"] as const;
export const queryFn_canShare = () => Share.canShare();

export const query_canShare = {
	queryFn: queryFn_canShare,
	queryKey: queryKey_canShare,
	initialData: false,
} satisfies UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_canShare>>,
	Error,
	Awaited<ReturnType<typeof queryFn_canShare>>,
	typeof queryKey_canShare
>;
