import { IonText } from "@ionic/react";
import { cx } from "@/lib/style/cva.config";

export function AuthPageHeaderTitle({
	parts,
	...props
}: React.ComponentPropsWithoutRef<"div"> & {
	parts: {
		title: React.ComponentProps<typeof IonText>;
		subtitle?: React.ComponentProps<typeof IonText>;
	};
}) {
	return (
		<div className="flex flex-col gap-2 text-brown-700">
			<IonText {...parts.title} className={cx("text-4xl font-bold", parts.title.className)} />
			{!!parts?.subtitle && <IonText {...parts?.subtitle} />}

			{props.children}
		</div>
	);
}
