import { IonBadge } from "@ionic/react";
import Color from "color";
import { type CoreValueItem } from "@/lib/backend/api";
import { cx } from "@/lib/style/cva.config";

interface Props extends React.ComponentProps<typeof IonBadge> {
	value: Pick<CoreValueItem, "id" | "name" | "emoji" | "color">;
}

export function CoreValueBadge({ value, ...props }: Props) {
	const { emoji, name, color } = value;

	return (
		<IonBadge
			{...props}
			className={cx(
				"ion-bg-[--background] inline-flex items-center gap-1.5 rounded-full px-2.5 py-1.5 text-sm font-semibold text-[--foreground]",
				props.className,
			)}
			style={{
				"--background": Color(color).alpha(0.25).rgb().toString(),
				"--foreground": Color(color).darken(0.625).rgb().toString(),
			}}
		>
			<span>{emoji}</span>
			<span>{name}</span>
		</IonBadge>
	);
}
