import { IonPage, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { Content } from "@/components/Global/Content";
import { Loading } from "@/components/Loading";
import { Haptics } from "@/lib/capacitor/Haptics";
import { Routes } from "@/lib/router";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { useAuthFlowStore } from "@/lib/store/auth-flow";

export function AuthOAuthRedirect() {
	const posthog = usePostHog();
	const router = useIonRouter();
	const params = Routes.AuthOAuthRedirect.useParams();

	const authenticate = useSelectorAuthStore.use.authenticate();

	useEffect(() => {
		if (router.routeInfo.pathname !== Routes.AuthOAuthRedirect()) {
			return;
		}

		if (params.error) {
			router.push(
				Routes.AuthSignInMagic({
					error: params.error,
				}),
				"root",
				"replace",
			);
			return;
		}

		const { accessToken, refreshToken } = params;

		if (!accessToken) {
			posthog.capture("User OAuth Redirect", { success: false });
			router.push(Routes.AuthSignInMagic(), "root", "replace");
			return;
		}

		posthog.capture("User OAuth Redirect", { success: true });
		Haptics.impact(Haptics.ImpactStyle.Heavy);
		authenticate({ accessToken, refreshToken });
		useAuthFlowStore.getState().reset();
	}, [params]);

	return (
		<IonPage>
			<Content fullscreen className="part-scroll:items-center part-scroll:justify-center">
				<Loading />
			</Content>
		</IonPage>
	);
}
