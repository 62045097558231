import {
	type DefaultError,
	type QueryFunctionContext,
	type UseQueryOptions,
} from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";

export const queryKey_coreValuesReflection = ["core-values", "reflection"] as const;
export const queryFn_coreValuesReflection = (
	context: QueryFunctionContext<typeof queryKey_coreValuesReflection>,
) =>
	extractionWrapper(
		backendService.corevalues.coreValuesControllerGetFriendCheckCoreValueAnalysis(
			{
				type: "reflection",
			},
			{ signal: context.signal },
		),
	);

export const query_coreValuesReflection = {
	queryKey: queryKey_coreValuesReflection,
	queryFn: queryFn_coreValuesReflection,
} satisfies UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_coreValuesReflection>>,
	DefaultError,
	Awaited<ReturnType<typeof queryFn_coreValuesReflection>>,
	typeof queryKey_coreValuesReflection
>;
