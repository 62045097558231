import { IonPage, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthResendButton } from "@/components/Auth/ResendButton";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import {
	type EmailVerificationCodeDto,
	type VerifySignupVerificationCodeDto,
} from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { VERIFICATION_CODE_LENGTH } from "@/lib/constants";
import { envelopeOpenIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function AuthSignInMagicVerify() {
	const posthog = usePostHog();
	const { t, i18n } = useTranslation();
	const authenticate = useSelectorAuthStore.use.authenticate();
	const state = useAuthFlowStore();
	const router = useIonRouter();
	const searchParams = new URLSearchParams(router.routeInfo.search);
	const queryParamEmail = searchParams.get("email");
	const queryParamVerificationCode =
		searchParams.get("code") || searchParams.get("verificationCode");

	const email = queryParamEmail || state.email;
	const verificationCode = queryParamVerificationCode || state.verificationCode;

	const mutationSendCode = useExtractedMutation({
		mutationKey: ["auth", "magic", "send"],
		fn: (variables: EmailVerificationCodeDto) =>
			backendService.auth.authControllerSendMagicLink(variables, { format: "text" }),
	});

	const mutationVerifyCode = useExtractedMutation({
		mutationKey: ["auth", "magic", "verify"],
		fn: (variables: VerifySignupVerificationCodeDto) =>
			backendService.auth.authControllerVerifyMagicLink(variables),
	});

	const form = useZodForm({
		defaultValues: { email, verificationCode },
		onSubmit: ({ value, formApi }) =>
			mutationVerifyCode.mutateAsync(value, {
				onSuccess: (data) => {
					if (!data) {
						throw new Error("Sign In Failed", { cause: "No data" });
					}

					posthog.capture("User Sign In", { method: "magic-link", success: true });
					authenticate(data);
					useAuthFlowStore.getState().reset();
					router.push(Routes.Dashboard(), "none", "replace");
				},
				onError: (error) => {
					posthog.capture("User Sign In", { method: "magic-link", success: false });

					let message: string;

					if (error instanceof Error) {
						message = error.message;
					} else {
						message = getErrorText({
							language: i18n.language,
							errors: error.message.map((message) => {
								switch (message) {
									case "Unauthorized":
										return t("fields.verificationCode.errors.incorrect");
									default:
										return message;
								}
							}),
						});
					}

					formApi.setFieldMeta("verificationCode", (current) => ({
						...current,
						errorMap: {
							onSubmit: message,
						},
					}));
				},
			}),
	});

	useEffect(() => {
		if (!email || !verificationCode) {
			return;
		}

		form.handleSubmit();
	}, []);

	if (!email) {
		return <Redirect to={Routes.AuthSignInMagic()} />;
	}

	return (
		<IonPage>
			<AuthHeader parts={{ backButton: { defaultHref: Routes.AuthSignInMagic() } }} />
			<AuthContent>
				<TitleIcon icon={envelopeOpenIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.sign-in.steps.magic-verify.title") },
						subtitle: {
							children: (
								<Trans
									i18nKey="page.auth.sign-in.steps.magic-verify.subtitle"
									values={{ email: state.email }}
								/>
							),
						},
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-1 flex-col gap-6"
				>
					<form.Field
						name="email"
						children={(field) => (
							<input
								type="email"
								name={field.name}
								autoComplete="email"
								readOnly
								className="invisible hidden"
								defaultValue={field.state.value}
							/>
						)}
					/>

					<form.Field
						name="verificationCode"
						validators={{
							onSubmit: z.coerce
								.number({
									invalid_type_error: t("fields.verificationCode.errors.number"),
								})
								.min(
									VERIFICATION_CODE_LENGTH,
									t("fields.verificationCode.errors.min", {
										number: VERIFICATION_CODE_LENGTH,
									}),
								),
						}}
						children={(field) => (
							<FormInput
								field={field}
								type="text"
								autofocusOnIonViewDidEnter
								inputMode="numeric"
								label={t("fields.verificationCode.label")}
								placeholder={t("fields.verificationCode.placeholder")}
								minlength={VERIFICATION_CODE_LENGTH}
								maxlength={VERIFICATION_CODE_LENGTH}
							/>
						)}
					/>

					<div className="flex flex-col gap-2">
						<form.Subscribe
							selector={(state) => [state.canSubmit, state.isSubmitting]}
							children={([canSubmit, isSubmitting]) => (
								<AuthSubmitButton disabled={!canSubmit} isSubmitting={isSubmitting}>
									{t("page.auth.sign-in.steps.magic-verify.action.primary")}
								</AuthSubmitButton>
							)}
						/>

						<AuthResendButton
							disabled={mutationSendCode.isPending}
							onClick={({ controllers }) => {
								posthog.capture("OTP Resend");

								mutationSendCode.mutate(
									{ email: state.email },
									{
										onSettled: () => {
											controllers.resetCountdown();
											controllers.startCountdown();
										},
									},
								);
							}}
						/>
					</div>
				</form>
			</AuthContent>
		</IonPage>
	);
}
