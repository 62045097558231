import {
	Keyboard as CapacitorKeyboard,
	type KeyboardInfo,
	KeyboardResize,
	type KeyboardResizeOptions,
	KeyboardStyle,
	type KeyboardStyleOptions,
} from "@capacitor/keyboard";
import { Capacitor } from "@/lib/capacitor";
import { logger } from "@/lib/logger";

export const Keyboard = (function () {
	async function show() {
		if (Capacitor.Platform !== "android") {
			logger.debug("Keyboard.show() is only available on Android");
			return;
		}

		return await CapacitorKeyboard.show();
	}

	async function hide() {
		if (Capacitor.Platform === "web") {
			logger.debug("Keyboard.hide() is not available on Web");
			return;
		}

		return await CapacitorKeyboard.hide();
	}

	async function setAccessoryBarVisible(
		isVisible: Parameters<typeof CapacitorKeyboard.setAccessoryBarVisible>[0]["isVisible"] = true,
	) {
		if (Capacitor.Platform !== "ios") {
			logger.debug("Keyboard.setAccessoryBarVisible() is only available on iOS");
			return;
		}

		return await CapacitorKeyboard.setAccessoryBarVisible({ isVisible });
	}

	async function setScroll(
		isDisabled: Parameters<typeof CapacitorKeyboard.setScroll>[0]["isDisabled"] = false,
	) {
		if (Capacitor.Platform !== "ios") {
			logger.debug("Keyboard.setScroll() is only available on iOS");
			return;
		}

		return await CapacitorKeyboard.setScroll({ isDisabled });
	}

	async function setStyle(style: KeyboardStyleOptions["style"] = KeyboardStyle.Default) {
		if (Capacitor.Platform !== "ios") {
			logger.debug("Keyboard.setStyle() is only available on iOS");
			return;
		}

		return await CapacitorKeyboard.setStyle({ style });
	}

	async function setResizeMode(mode: KeyboardResizeOptions["mode"] = KeyboardResize.Ionic) {
		if (Capacitor.Platform !== "ios") {
			logger.debug("Keyboard.setResizeMode() is only available on iOS");
			return;
		}

		return await CapacitorKeyboard.setResizeMode({ mode });
	}

	async function getResizeMode() {
		if (Capacitor.Platform !== "ios") {
			logger.debug("Keyboard.getResizeMode() is only available on iOS");
			return;
		}

		return (await CapacitorKeyboard.getResizeMode()).mode;
	}

	function addWillShowListener(listenerFunc: (info: KeyboardInfo) => void) {
		if (Capacitor.Platform === "web") {
			logger.debug("Keyboard.addWillShowListener() is not implemented on web");
			return;
		}

		return CapacitorKeyboard.addListener("keyboardWillShow", listenerFunc);
	}

	function addDidShowListener(listenerFunc: (info: KeyboardInfo) => void) {
		if (Capacitor.Platform === "web") {
			logger.debug("Keyboard.addDidShowListener() is not implemented on web");
			return;
		}

		return CapacitorKeyboard.addListener("keyboardDidShow", listenerFunc);
	}

	function addWillHideListener(listenerFunc: () => void) {
		if (Capacitor.Platform === "web") {
			logger.debug("Keyboard.addWillHideListener() is not implemented on web");
			return;
		}

		return CapacitorKeyboard.addListener("keyboardWillHide", listenerFunc);
	}

	function addDidHideListener(listenerFunc: () => void) {
		if (Capacitor.Platform === "web") {
			logger.debug("Keyboard.addDidHideListener() is not implemented on web");
			return;
		}

		return CapacitorKeyboard.addListener("keyboardDidHide", listenerFunc);
	}

	function removeAllListeners() {
		if (Capacitor.Platform === "web") {
			logger.debug("Keyboard.removeAllListeners() is not implemented on web");
			return;
		}

		return CapacitorKeyboard.removeAllListeners();
	}

	return {
		...CapacitorKeyboard,
		Resize: KeyboardResize,
		Style: KeyboardStyle,
		show,
		hide,
		setAccessoryBarVisible,
		setScroll,
		setStyle,
		setResizeMode,
		getResizeMode,
		addWillShowListener,
		addDidShowListener,
		addWillHideListener,
		addDidHideListener,
		removeAllListeners,
	};
})();

export type * from "@capacitor/keyboard";
