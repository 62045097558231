import { IonIcon, IonPage, IonSpinner, IonText, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { useCountdown } from "usehooks-ts";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";
import { Capacitor } from "@/lib/capacitor";
import { Dialog } from "@/lib/capacitor/Dialog";
import { arrowLeftStartOnRectangleIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { envelopeIcon, exclamationTriangleIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { useMe } from "@/lib/query/functions/user/me";
import { Routes } from "@/lib/router";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export function NoAccess() {
	const { t } = useTranslation();
	const router = useIonRouter();
	const params = Routes.NoAccess.useParams();
	const isAuthenticated = useAuthStore(authSelectors.isAuthenticated);

	const [count, controllers] = useCountdown({ countStart: 10 });

	const [me] = useMe();
	const queryAccess = useQuery({
		queryKey: ["user", "me", "access"],
		queryFn: (context) =>
			extractionWrapper(backendService.user.usersControllerGetMyAccess({ signal: context.signal })),
		enabled: isAuthenticated,
	});
	const hasAccess = queryAccess.data?.hasAccess;

	useEffect(() => {
		if (hasAccess === true) {
			router.push(params.path ?? Routes.Dashboard(), "none", "replace");
		}
	}, [hasAccess, params.path]);

	useEffect(() => {
		controllers.startCountdown();

		return () => {
			controllers.stopCountdown();
		};
	}, []);

	return (
		<IonPage>
			<Content className="part-scroll:items-center part-scroll:justify-center">
				<div className="flex w-full flex-col items-center justify-center gap-2 *:text-brown-700">
					<IonIcon
						icon={exclamationTriangleIcon}
						className="rounded-full bg-brown-100 p-4 text-4xl"
					/>
					<IonText className="text-3xl font-bold">{t("no-access.title")}</IonText>
					<IonText>{t("no-access.description")}</IonText>
					<IonText className="font-medium text-brown-700">
						{t("no-access.contact")}{" "}
						<a
							href="mailto:jp@stijlbreuk.nl"
							className="ms-0.5 inline-flex translate-y-px items-center gap-1 underline hover:underline-offset-2 active:underline-offset-2"
						>
							<IonIcon icon={envelopeIcon} className="size-3.5 translate-y-px" />
							jp@stijlbreuk.nl
						</a>
					</IonText>
				</div>

				<Button
					onClick={() => {
						controllers.resetCountdown();
						queryAccess.refetch();
					}}
					disabled={queryAccess.isFetching || count !== 0}
					className="min-h-0"
				>
					{queryAccess.isFetching && (
						<IonSpinner slot="start" className="-my-8 me-2 ms-0 scale-75" />
					)}{" "}
					{t("no-access.try-again", { count })}
				</Button>

				<Button
					className="min-h-0 text-base ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl"
					fill="clear"
					onClick={() =>
						new Promise((resolve) => {
							if (Capacitor.Platform !== "web") {
								Dialog.confirm({
									title: t("auth.sign-out.dialog.title"),
									message: t("auth.sign-out.dialog.message"),
									okButtonTitle: t("auth.sign-out.dialog.actions.confirm"),
									cancelButtonTitle: t("auth.sign-out.dialog.actions.cancel"),
								}).then((confirmed) => {
									if (confirmed) {
										resolve(undefined);
									}
								});
							} else {
								resolve(undefined);
							}
						}).then(useAuthStore.getState().unauthenticate)
					}
				>
					<IonIcon slot="start" icon={arrowLeftStartOnRectangleIcon} className="-my-1 size-4" />
					{t("auth.sign-out.name")}
				</Button>

				<pre className="font-mono mx-auto mt-8 w-full rounded-lg bg-brown-100 p-4 text-sm">
					<strong>Email:</strong> {me?.email}
					<br />
					<strong>Access:</strong> {hasAccess ? "Yes" : "No"}
					<br />
					<strong>Date:</strong> {new Date(queryAccess.dataUpdatedAt).toLocaleString()}
				</pre>
			</Content>
		</IonPage>
	);
}
