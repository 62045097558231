import {
	type DefaultError,
	type QueryFunctionContext,
	type UseQueryOptions,
} from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";

export const queryKey_coreValuesFriendCheck = ["core-values", "friend-check"] as const;
export const queryFn_coreValuesFriendCheck = (
	context: QueryFunctionContext<typeof queryKey_coreValuesFriendCheck>,
) =>
	extractionWrapper(
		backendService.corevalues.coreValuesControllerGetFriendCheckCoreValueAnalysis(
			{
				type: "friendcheck",
			},
			{ signal: context.signal },
		),
	);

export const query_coreValuesFriendCheck = {
	queryKey: queryKey_coreValuesFriendCheck,
	queryFn: queryFn_coreValuesFriendCheck,
} satisfies UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_coreValuesFriendCheck>>,
	DefaultError,
	Awaited<ReturnType<typeof queryFn_coreValuesFriendCheck>>,
	typeof queryKey_coreValuesFriendCheck
>;
