import { backendService } from "@/lib/backend";
import { getBasePath } from "@/lib/backend/getBasePath";
import { Routes } from "@/lib/router";

export type OAuthType = "google" | "apple";

export function getOAuthUrl(type: OAuthType, options?: { callbackUrl?: string }) {
	const searchParams = new URLSearchParams({
		callbackUrl: getBasePath(Routes.AuthOAuthRedirect()),
		...options,
	});

	return `${backendService.baseUrl}/auth/${type}?${searchParams.toString()}`;
}
