import { merge } from "lodash-es";
import { AuthAvatar } from "@/components/Auth/Avatar";
import { getAvatarUrl } from "@/lib/getAvatarUrl";
import { useMe } from "@/lib/query/functions/user/me";
import { cx } from "@/lib/style/cva.config";

export function AvatarMe(props: React.ComponentProps<typeof AuthAvatar>) {
	const [me] = useMe();

	return (
		<AuthAvatar
			{...props}
			className={cx("aspect-square size-24 rounded-full contain-paint", props.className)}
			parts={merge(
				{
					img: { src: getAvatarUrl(me), referrerPolicy: "no-referrer" },
				} satisfies (typeof props)["parts"],
				props.parts,
			)}
		/>
	);
}
