import { useEffect, useState } from "react";
import { type StoreApi, type UseBoundStore } from "zustand";

type PersistListener<S> = (state: S) => void;

export const useStoreHydration = <
	S extends UseBoundStore<
		StoreApi<object> & {
			persist: {
				hasHydrated: () => boolean;
				onHydrate: (fn: PersistListener<unknown>) => () => void;
				onFinishHydration: (fn: PersistListener<unknown>) => () => void;
			};
		}
	>,
>(
	useStore: S,
) => {
	const [hydrated, setHydrated] = useState(false);

	useEffect(() => {
		// Note: This is just in case you want to take into account manual rehydration.
		// You can remove the following line if you don't need it.
		const unsubHydrate = useStore.persist.onHydrate(() => setHydrated(false));

		const unsubFinishHydration = useStore.persist.onFinishHydration(() => setHydrated(true));

		setHydrated(useStore.persist.hasHydrated());

		return () => {
			unsubHydrate();
			unsubFinishHydration();
		};
	}, []);

	return hydrated;
};
