import {
	useFeatureFlagEnabled as usePostHogFeatureFlagEnabled,
	useFeatureFlagVariantKey as usePostHogFeatureFlagVariantKey,
} from "posthog-js/react";

export function useFeatureFlagEnabled(flag: string): boolean {
	const value = usePostHogFeatureFlagEnabled(flag);

	switch (value) {
		case true:
		case false:
			return value;

		default:
			return false;
	}
}

export type ExperimentKey<T extends string> = T | "control";

export function useExperimentKey<TVariant extends string>(
	flag: string,
	variants: ReadonlyArray<TVariant>,
): ExperimentKey<TVariant> {
	const value = usePostHogFeatureFlagVariantKey(flag);

	if (variants.includes(value as TVariant)) {
		return value as TVariant;
	}

	return "control";
}
