import { IonCard } from "@ionic/react";
import { forwardRef } from "react";
import { cx } from "@/lib/style/cva.config";

export const FriendCheckCard = forwardRef<HTMLIonCardElement, React.ComponentProps<typeof IonCard>>(
	function FriendCheckQuestionCard(props, ref) {
		return (
			<IonCard
				ref={ref}
				{...props}
				className={cx(
					"m-0 flex flex-col justify-between rounded-2xl px-4 py-3 text-brown-700 shadow-none ion-bg-brown-100 part-native:flex part-native:flex-col part-native:justify-between",
					!!props.onClick && "ion-activatable cursor-pointer",
					props.className,
				)}
			>
				{props.children}
			</IonCard>
		);
	},
);
