import {
	CameraDirection,
	type CameraPluginPermissions,
	CameraResultType,
	CameraSource,
	Camera as CapacitorCamera,
	type GalleryImageOptions,
	type ImageOptions,
} from "@capacitor/camera";
import { Capacitor } from "@/lib/capacitor";

export const Camera = (function () {
	async function getPhoto(options: ImageOptions) {
		return await CapacitorCamera.getPhoto(options);
	}

	async function pickImages(options: GalleryImageOptions = {}) {
		return (await CapacitorCamera.pickImages(options)).photos;
	}

	async function pickLimitedLibraryPhotos() {
		return (await CapacitorCamera.pickLimitedLibraryPhotos()).photos;
	}

	async function getLimitedLibraryPhotos() {
		return (await CapacitorCamera.getLimitedLibraryPhotos()).photos;
	}

	async function checkPermissions() {
		if (Capacitor.Platform === "web") {
			return { camera: "granted", photos: "granted" };
		}

		return await CapacitorCamera.checkPermissions();
	}

	async function requestPermissions(
		permissions: CameraPluginPermissions["permissions"] = ["camera", "photos"],
	) {
		return await CapacitorCamera.requestPermissions({ permissions });
	}

	return {
		...CapacitorCamera,
		getPhoto,
		pickImages,
		pickLimitedLibraryPhotos,
		getLimitedLibraryPhotos,
		checkPermissions,
		requestPermissions,
		ResultType: CameraResultType,
		Source: CameraSource,
		Direction: CameraDirection,
	};
})();

export type * from "@capacitor/camera";
