import { IonButtons, IonIcon, IonPage, IonText, useIonRouter } from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthStrategyOAuth } from "@/components/Auth/Strategy/OAuth";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import { type EmailVerificationCodeDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { PAGE_PRIVACY_POLICY_URL, PAGE_TERMS_AND_CONDITIONS_URL } from "@/lib/constants";
import { envelopeIcon, sparklesIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function AuthSignUp() {
	const posthog = usePostHog();
	const { t, i18n } = useTranslation();
	const router = useIonRouter();
	const state = useAuthFlowStore();

	const mutationSendCode = useExtractedMutation({
		mutationKey: ["auth", "signup", "verification", "send"],
		fn: (variables: EmailVerificationCodeDto) =>
			backendService.auth.authControllerSendSignupVerificationCode(variables, { format: "text" }),
	});

	const form = useZodForm({
		defaultValues: { email: state.email },
		onSubmit: ({ value, formApi }) =>
			mutationSendCode.mutateAsync(value, {
				onSuccess: (_data, variables) => {
					posthog.capture("User Sign Up Send");
					useAuthFlowStore.setState(variables);
					router.push(Routes.AuthSignUpVerify(), "forward");
				},
				onError: (error) => {
					let message: string;

					if (error instanceof Error) {
						message = error.message;
					} else {
						message = getErrorText({
							language: i18n.language,
							errors: error.message.map((message) => {
								switch (message) {
									case "Email exists!":
										return t("fields.email.errors.used");
									default:
										return message;
								}
							}),
						});
					}

					formApi.setFieldMeta("email", (current) => ({
						...current,
						errorMap: {
							onSubmit: message,
						},
					}));
				},
			}),
	});

	return (
		<IonPage>
			<AuthHeader>
				<IonButtons slot="primary">
					<Button
						routerLink={Routes.AuthSignInMagic()}
						routerDirection="back"
						color="tertiary"
						size="small"
						className="touch-target part-native:text-brown-600"
					>
						<span>
							<Trans i18nKey="page.auth.sign-up.header.primary-button" />
						</span>
					</Button>
				</IonButtons>
			</AuthHeader>
			<AuthContent>
				<TitleIcon icon={sparklesIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.sign-up.title") },
						subtitle: { children: t("page.auth.sign-up.subtitle") },
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						validators={{
							onSubmit: z.string().email(t("fields.email.errors.invalid")),
						}}
						children={(field) => (
							<FormInput
								field={field}
								type="email"
								label={t("fields.email.label")}
								autocomplete="email"
								inputMode="email"
								placeholder={t("fields.email.placeholder")}
							>
								<IonIcon slot="start" icon={envelopeIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton disabled={!canSubmit} isSubmitting={isSubmitting}>
								{t("page.auth.sign-up.action.primary")}
							</AuthSubmitButton>
						)}
					/>
				</form>

				<AuthStrategyOAuth />

				<IonText className="px-2 py-8 text-center text-sm text-brown-500">
					<Trans
						i18nKey="page.auth.sign-up.disclaimer"
						values={{
							termsAndConditions: t("terms-and-conditions"),
							privacyPolicy: t("privacy-policy"),
						}}
						components={[
							<a
								key="0"
								href={PAGE_TERMS_AND_CONDITIONS_URL}
								target="_blank"
								rel="noreferrer"
								className="font-semibold text-brown-500 underline"
							/>,
							<a
								key="1"
								href={PAGE_PRIVACY_POLICY_URL}
								target="_blank"
								rel="noreferrer"
								className="font-semibold text-brown-500 underline"
							/>,
						]}
					/>
				</IonText>
			</AuthContent>
		</IonPage>
	);
}
