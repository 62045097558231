import { createAnimation } from "@ionic/react";

export function fadeAnimation(baseEl: HTMLElement) {
	const root = baseEl.shadowRoot;
	const backdrop = root?.querySelector("ion-backdrop");
	const modalWrapper = root?.querySelector(".modal-wrapper");

	if (!modalWrapper || !backdrop) {
		throw new Error("No element found");
	}

	const backdropAnimation = createAnimation()
		.addElement(backdrop)
		.fromTo("opacity", "0.01", "var(--backdrop-opacity)");

	const modalWrapperAnimation = createAnimation()
		.addElement(modalWrapper)
		.fromTo("transform", "scale(1)", "scale(1)") // Required because it otherwise is not shown
		.fromTo("opacity", 0, 1);

	return createAnimation()
		.addElement(baseEl)
		.easing("ease-in-out")
		.duration(500)
		.addAnimation([backdropAnimation, modalWrapperAnimation]);
}
