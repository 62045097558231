import {
	Haptics as CapacitorHaptics,
	type ImpactOptions,
	ImpactStyle,
	type NotificationOptions,
	NotificationType,
	type VibrateOptions,
} from "@capacitor/haptics";

export const Haptics = (function () {
	async function impact(style: ImpactOptions["style"] = ImpactStyle.Medium) {
		return await CapacitorHaptics.impact({ style });
	}

	async function notification(type: NotificationOptions["type"] = NotificationType.Success) {
		return await CapacitorHaptics.notification({ type });
	}

	async function vibrate(duration?: VibrateOptions["duration"]) {
		return await CapacitorHaptics.vibrate(duration !== undefined ? { duration } : undefined);
	}

	async function selectionStart() {
		return await CapacitorHaptics.selectionStart();
	}

	async function selectionChanged() {
		return await CapacitorHaptics.selectionChanged();
	}

	async function selectionEnd() {
		return await CapacitorHaptics.selectionEnd();
	}

	return {
		...CapacitorHaptics,
		ImpactStyle,
		impact,
		NotificationType,
		notification,
		vibrate,
		selectionStart,
		selectionChanged,
		selectionEnd,
	};
})();

export type * from "@capacitor/haptics";
