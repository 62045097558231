import {
	IonButtons,
	IonChip,
	IonHeader,
	IonIcon,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonSkeletonText,
	IonText,
	IonTitle,
	IonToolbar,
	useIonRouter,
} from "@ionic/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Image_nl from "@/assets/nl/explain-reflection@2x.png";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { ReflectionChatBubble } from "@/components/Reflection/Chat/Bubble";
import { Haptics } from "@/lib/capacitor/Haptics";
import { getAnswer, hasAnswer } from "@/lib/functions/answer";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { questionMarkCircleIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { query_reflection } from "@/lib/query/functions/reflection/all";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";

export function Reflection() {
	const { t, i18n } = useTranslation();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const presentModal = useSelectorModalStore.use.present();
	const [myData, { query: queryMyData }] = useMyData();
	const query = useQuery(query_reflection);

	const sortedData = useMemo(
		() =>
			[...(query.data ?? [])].sort((a, b) => {
				const completed =
					Number(!!b.firstAnswer && b.firstAnswer.length > 0) -
					Number(!!a.firstAnswer && a.firstAnswer.length > 0);

				if (completed === 0) {
					return (
						Number(hasAnswer(myData.reflection.questions[b.id])) -
						Number(hasAnswer(myData.reflection.questions[a.id]))
					);
				}

				return completed;
			}),
		[query.data, myData.reflection.questions],
	);

	const [filter, setFilter] = useState<"open" | "completed">("open");

	const items = {
		open: sortedData.filter((question) => !question.isCompleted) ?? [],
		completed: sortedData.filter((question) => question.isCompleted) ?? [],
	} satisfies Record<typeof filter, typeof sortedData>;

	useEffect(() => {
		if (query.status === "pending") {
			return;
		}

		if (items.open.length === 0) {
			setFilter("completed");
		}
	}, [query.status, items.open.length]);

	return (
		<IonPage>
			{!myData.modal.reflectionExplainerGeneral.seen ? (
				<Content className="part-scroll:justify-center">
					<img
						src={{ nl: Image_nl }[i18n.language]}
						className="mx-auto max-h-[50vmin] max-w-[50vmin]"
					/>

					<div className="flex flex-col items-center gap-8">
						<div className="flex flex-col items-center gap-1">
							<IonText className="text-center text-3xl font-bold text-brown-700">
								{t("reflection.empty-page.title")}
							</IonText>
							<IonText className="text-pretty text-center text-lg text-brown-600">
								{t("reflection.empty-page.message")}
							</IonText>
						</div>
						<Button
							expand="block"
							className="w-full text-lg font-bold sm:max-w-56"
							onClick={() => {
								const firstId = items.open[0]?.id;

								presentModal(
									"reflectionExplainerGeneral",
									firstId
										? {
												onCompleted: () =>
													presentModal("reflectionExplainerFirstQuestion", { id: firstId }),
											}
										: undefined,
								);
							}}
						>
							{t("reflection.empty-page.action")}
						</Button>
					</div>
				</Content>
			) : (
				<>
					<IonHeader>
						<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
							<IonTitle className="text-lg font-bold ion-text-brown-700">
								{t("reflection.name")}
							</IonTitle>

							<IonButtons collapse slot="primary">
								<Button
									shape="round"
									color="light"
									size="small"
									className="touch-target min-h-0 part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
									onClick={() => presentModal("reflectionExplainerGeneral")}
								>
									<IonIcon slot="icon-only" icon={questionMarkCircleIcon} />
								</Button>
							</IonButtons>
						</Toolbar>
					</IonHeader>

					<Content className="part-scroll:gap-4">
						<IonHeader collapse="condense" className="-mt-[--padding-top]">
							<IonToolbar className="ion-px-0">
								<IonTitle className="p-0 text-start text-3xl font-bold ion-text-brown-700">
									{t("reflection.name")}
								</IonTitle>

								<IonButtons collapse slot="primary">
									<Button
										shape="round"
										color="light"
										size="small"
										className="touch-target min-h-0 part-icon:m-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
										onClick={() => presentModal("reflectionExplainerGeneral")}
									>
										<IonIcon slot="icon-only" icon={questionMarkCircleIcon} className="size-5" />
									</Button>
								</IonButtons>
							</IonToolbar>
						</IonHeader>

						<IonRefresher
							slot="fixed"
							onIonRefresh={(event) =>
								Promise.all([query.refetch(), queryMyData.refetch()]).then(() =>
									event.detail.complete(),
								)
							}
						>
							<IonRefresherContent />
						</IonRefresher>

						<div className="flex flex-col gap-4">
							{items.open.length > 0 && (
								<div className="flex select-none flex-row gap-2 text-sm font-semibold tabular-nums text-brown-700 *:border *:border-brown-300 *:bg-transparent aria-selected:*:border-transparent aria-selected:*:bg-brown-100">
									<IonChip
										aria-selected={filter === "open"}
										onClick={() => {
											PreferredHaptics.impact(Haptics.ImpactStyle.Light);
											setFilter("open");
										}}
									>
										{t("reflection.filtering.filter.open", { count: items.open.length })}
									</IonChip>
									<IonChip
										aria-selected={filter === "completed"}
										onClick={() => {
											PreferredHaptics.impact(Haptics.ImpactStyle.Light);
											setFilter("completed");
										}}
									>
										{t("reflection.filtering.filter.completed", { count: items.completed.length })}
									</IonChip>
								</div>
							)}

							{query.isLoading ? (
								Array.from({ length: 3 }).map((_, index) => (
									<IonSkeletonText
										key={index}
										animated
										className="h-[9.5rem] border border-brown-200 bg-brown-100 ion-bg-brown-100 ion-rounded-3xl"
									/>
								))
							) : items[filter].length === 0 ? (
								<span className="px-4 py-8 text-center text-sm font-semibold">
									{t("reflection.filtering.empty")}
								</span>
							) : (
								items[filter].map((question) => {
									const hasFirstAnswer = hasAnswer(question.firstAnswer);
									const hasInProgressAnswer = hasAnswer(myData.reflection.questions[question.id]);
									const hasStarted = question.isCompleted || hasFirstAnswer || hasInProgressAnswer;

									return (
										<ReflectionCard
											key={question.id}
											className="flex flex-col gap-3"
											onClick={() => {
												if (
													!sortedData.some(
														(question) =>
															question.isCompleted ||
															hasAnswer(question.firstAnswer) ||
															hasAnswer(myData.reflection.questions[question.id]),
													) &&
													!myData.modal.reflectionExplainerGeneral.seen
												) {
													presentModal("reflectionExplainerGeneral", {
														onCompleted: () =>
															presentModal("reflectionExplainerFirstQuestion", {
																id: question.id,
															}),
													});
												} else if (hasFirstAnswer) {
													router.push(Routes.ReflectionQuestion({ id: question.id }));
												} else {
													presentModal("reflectionQuestionFirstAnswer", { id: question.id });
												}
											}}
										>
											<strong className="flex-1 text-pretty text-xl font-semibold">
												{question.question}
											</strong>
											{question.isCompleted ? (
												<>
													<div data-ph-no-capture className="font-normal">
														{getAnswer(question.firstAnswer, { language: i18n.language })}
													</div>
													<div className="flex flex-wrap gap-1">
														{question.coreValues && question.coreValues.length > 0 ? (
															question.coreValues.map((coreValue) => (
																<CoreValueBadge key={coreValue.id} value={coreValue} />
															))
														) : (
															<IonChip
																className="m-0 min-h-0 bg-brown-200 py-1 text-brown-700"
																onClick={() => {
																	PreferredHaptics.impact();
																	presentModal("reflectionQuestionCompleted", {
																		id: question.id,
																	});
																}}
															>
																{t("reflection.core-values-generation.title")}
																<IonIcon icon={arrowPathIcon} className="size-4" />
															</IonChip>
														)}
													</div>
												</>
											) : (
												hasStarted && (
													<ReflectionChatBubble
														sender="user"
														className={cx(
															"me-0 ms-auto ion-bg-brown-200",
															!hasFirstAnswer &&
																hasInProgressAnswer &&
																"in-progress-background italic",
														)}
														parts={{
															smallBubble: {
																className: cx(
																	"-top-3 right-0",
																	!hasFirstAnswer &&
																		hasInProgressAnswer &&
																		"in-progress-background size-2",
																),
															},
															text: {
																// @ts-expect-error Data is not typed
																"data-ph-no-capture": true,
																className: "px-1 -mx-1",
															},
														}}
													>
														{getAnswer(
															hasFirstAnswer
																? question.firstAnswer
																: myData.reflection.questions[question.id] || "",
															{ language: i18n.language },
														)}
													</ReflectionChatBubble>
												)
											)}
										</ReflectionCard>
									);
								})
							)}
						</div>
					</Content>
				</>
			)}
		</IonPage>
	);
}
