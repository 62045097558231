import { IonAvatar, IonIcon } from "@ionic/react";
import EmptyAvatar from "@/assets/empty_avatar.svg";
import { Button } from "@/components/Global/Button";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { cameraIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { cx } from "@/lib/style/cva.config";

export function AuthAvatar({
	withEdit = false,
	parts,
	...props
}: React.ComponentProps<typeof IonAvatar> & {
	withEdit?: boolean;
	parts?: { img?: React.ComponentPropsWithoutRef<"img"> };
}) {
	const PreferredHaptics = usePreferredHaptics();

	return (
		<IonAvatar
			{...props}
			className={cx(
				"relative size-20 ion-rounded-full",
				withEdit && "cursor-pointer",
				props.className,
			)}
			onClick={(event) => {
				if (!props?.onClick) {
					return;
				}

				PreferredHaptics.impact();
				props.onClick(event);
			}}
		>
			<img
				{...parts?.img}
				src={parts?.img?.src || EmptyAvatar}
				className={cx(
					"ph-no-capture",
					"absolute inset-0 -z-10 size-full rounded-full object-cover",
					parts?.img?.className,
				)}
			/>

			{withEdit && (
				<Button
					fill="default"
					className="absolute -bottom-1 -right-1 size-9 min-h-0 text-brown-500 opacity-100 part-icon:m-0 part-native:size-9 part-native:min-h-0 part-native:rounded-full part-native:bg-white part-native:p-1 part-native:text-lg part-native:shadow-[0px_4px_22px_0px_theme(colors.black/.25)]"
				>
					<IonIcon slot="icon-only" icon={cameraIcon} />
				</Button>
			)}
		</IonAvatar>
	);
}
