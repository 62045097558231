import {
	type DefaultError,
	type QueryFunctionContext,
	type UseQueryOptions,
} from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";

export const queryKey_friendCheck = ["friend-check", "submissions"] as const;
export const queryFn_friendCheck = (context: QueryFunctionContext<typeof queryKey_friendCheck>) =>
	extractionWrapper(
		backendService.friendcheck.friendcheckControllerGetAllSubmissions({
			signal: context.signal,
		}),
	);

export const query_friendCheck = {
	queryKey: queryKey_friendCheck,
	queryFn: queryFn_friendCheck,
} satisfies UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_friendCheck>>,
	DefaultError,
	Awaited<ReturnType<typeof queryFn_friendCheck>>,
	typeof queryKey_friendCheck
>;
