import { useEffect, useRef } from "react";
import { motion, useInView, useMotionValue, useSpring } from "framer-motion";
import { cx } from "@/lib/style/cva.config";

export function NumberTicker({
	value,
	checkInView = true,
	direction = "up",
	duration = 0.5,
	delay = 0,
	getTextContent = (value) => Intl.NumberFormat("en").format(value.toFixed(0)),
	...props
}: React.ComponentProps<typeof motion.span> & {
	value: number;
	checkInView?: boolean;
	direction?: "up" | "down";
	duration?: number; // duration in s
	delay?: number; // delay in s
	getTextContent?: (value: any) => string;
}) {
	const ref = useRef<HTMLSpanElement>(null);
	const motionValue = useMotionValue(direction === "down" ? value : 0);
	const springValue = useSpring(motionValue, {
		damping: 60,
		stiffness: 100,
		duration,
	});
	const isInView = useInView(ref, { once: true, margin: "0px" });

	useEffect(() => {
		if ((checkInView && isInView) || !checkInView) {
			setTimeout(() => {
				motionValue.set(direction === "down" ? 0 : value);
			}, delay * 1000);
		}
	}, [motionValue, isInView, delay, value, direction]);

	useEffect(() => {
		if (ref.current) {
			ref.current.textContent = getTextContent(motionValue.get());
		}
	}, []);

	useEffect(
		() =>
			springValue.on("change", (latest) => {
				if (ref.current) {
					ref.current.textContent = getTextContent(latest);
				}
			}),
		[springValue],
	);

	return (
		<motion.span
			ref={ref}
			{...props}
			className={cx("inline-block tabular-nums", props.className)}
		/>
	);
}
