import { IonCheckbox, IonModal, IonText, useIonRouter } from "@ionic/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { z } from "zod";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { fadeAnimation } from "@/components/Modal/animations";
import { backendService } from "@/lib/backend";
import { query_coreValues } from "@/lib/query/functions/core-values/all";
import { query_coreValuesReflection } from "@/lib/query/functions/core-values/reflection";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useQueryStream } from "@/lib/query/hooks/useQueryStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";
import { jsonParse } from "@/utils/json/parse";

const MotionButton = motion.create(Button);

export function ModalFriendCheckAnalysisGenerate() {
	const { t } = useTranslation();
	const router = useIonRouter();
	const contentRef = useRef<HTMLIonContentElement>(null);
	const modalRevealRef = useRef<HTMLIonModalElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modalConfirmRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.friendCheckAnalysisGenerate();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, queryMyProgression] = useMyProgression();
	const [, { mutation }] = useMyData();

	const [selectedValues, setSelectedValues] = useState<string[]>([]);

	const queryAllCoreValues = useQuery({ ...query_coreValues, enabled: modal.isOpen });
	const queryMyCoreValues = useQuery({ ...query_coreValuesReflection, enabled: modal.isOpen });

	const myCoreValues = queryMyCoreValues.data?.map((coreValue) => ({
		...coreValue,
		// Enrich with actual emojis and colors
		...queryAllCoreValues.data?.find((value) => value.name === coreValue.name),
	}));

	const [streamedSummary, querySummaryStream] = useQueryStream({
		queryKey: ["core-values", "friend-check", "summary-stream"],
		fn: (params) =>
			backendService.corevalues.coreValuesControllerGenerateCoreValueSummaryStream(
				{ type: "friendcheck" },
				params,
			),
	});

	const parsedSummary = jsonParse({
		input: streamedSummary || "",
		schema: z.object({ summary: z.string().nullish() }),
	});

	const [streamedCoreValues, queryCoreValuesStream] = useQueryStream({
		queryKey: ["core-values", "friend-check", "analysis-stream"],
		fn: (params) =>
			backendService.corevalues.coreValuesControllerGenerateCoreValueAnalysisStream(
				{ type: "friendcheck" },
				params,
			),
	});

	const parsedCoreValues = jsonParse({
		input: streamedCoreValues || "[]",
		schema: z.array(
			z
				.object({
					name: z.string().nullish(),
					score: z.coerce.number().nullish(),
					reason: z.string().nullish(),
				})
				.passthrough(),
		),
	});

	const coreValues = parsedCoreValues
		?.filter((coreValue) => queryAllCoreValues.data?.some((value) => value.name === coreValue.name))
		.map((coreValue) => ({
			...coreValue,
			// Enrich with actual emojis and colors
			...queryAllCoreValues.data?.find((value) => value.name === coreValue.name),
		}));

	return (
		<>
			<IonModal
				ref={modalRevealRef}
				isOpen={modal.isOpen}
				onWillPresent={() => present("friendCheckAnalysisGenerate")}
				onWillDismiss={() => modalRef.current?.present()}
				onClick={() => modalRevealRef.current?.dismiss()}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="cursor-pointer !ion-rounded-none ion-h-full ion-w-full"
			>
				<Content
					fullscreen
					scrollY={false}
					className="part-background:bg-gradient-to-b part-background:from-orange-500 part-background:to-[#FF944C] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
				>
					<IonText className="text-4xl font-bold">
						{t("modal.friend-check.analysis.generate.title")}
					</IonText>
					<IonText className="font-semibold text-gray-100">
						{t("modal.friend-check.analysis.generate.text")}
					</IonText>
				</Content>
			</IonModal>

			<IonModal
				ref={modalRef}
				onDidPresent={() =>
					queryCoreValuesStream.refetch().then(() => querySummaryStream.refetch())
				}
				onWillDismiss={() => dismiss("friendCheckAnalysisGenerate")}
				onDidDismiss={() => {
					router.push(Routes.CoreValues());
					queryMyProgression.refetch();

					setTimeout(() => {
						queryMyProgression.refetch();
					}, 500);
				}}
				backdropDismiss={false}
			>
				<Content
					ref={contentRef}
					fullscreen
					inModal
					className={cx(
						"part-scroll:gap-8",
						(querySummaryStream.isPending || queryCoreValuesStream.isPending) &&
							"last:*:snap-end last:*:scroll-mb-4 part-scroll:snap-y part-scroll:snap-proximity",
					)}
				>
					<CoreValueAvatarWithBackground streamed coreValues={myCoreValues} />

					<motion.div
						initial={{ opacity: 0, display: "none" }}
						animate={{ opacity: 1, display: "flex" }}
						transition={{ duration: 0.5 }}
						className="flex flex-col gap-2 rounded-xl border border-brown-200 bg-brown-100 p-4"
					>
						<IonText className="text-base font-semibold text-brown-500">
							{t("modal.friend-check.analysis.generate.summary")}
						</IonText>
						<div className="block min-h-[4lh] text-pretty text-lg font-semibold text-brown-700">
							{!parsedSummary?.summary ? (
								<>Loading...</>
							) : (
								parsedSummary?.summary?.split(" ").map((word, index) => (
									<motion.span
										key={word + index}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										transition={{ duration: 0.5 }}
										onAnimationComplete={() => contentRef.current?.scrollToBottom()}
									>
										{word}{" "}
									</motion.span>
								))
							)}
						</div>
					</motion.div>

					<motion.div
						initial="hidden"
						animate={querySummaryStream.isFetched ? "visible" : "hidden"}
						variants={{
							visible: { opacity: 1, display: "flex" },
							hidden: { opacity: 0, display: "none" },
						}}
						transition={{ delay: 1, duration: 0.5 }}
						className="flex flex-col gap-3"
					>
						<div className="flex flex-col">
							<IonText className="text-xl font-bold text-brown-600">
								{t("modal.friend-check.analysis.generate.core-values.title")}
							</IonText>
							<IonText className="text-base font-normal text-brown-600">
								{t("modal.friend-check.analysis.generate.core-values.description")}
							</IonText>
						</div>

						{querySummaryStream.isFetched && (
							<div className="flex flex-1 flex-col gap-3">
								{coreValues?.map((coreValue, index) => {
									const alreadyInList = queryMyCoreValues.data?.some(
										(coreValueAnalysis) => coreValueAnalysis.name === coreValue?.name,
									);

									const checked =
										alreadyInList || (!!coreValue?.id && selectedValues.includes(coreValue?.id));

									return (
										<motion.div
											key={index}
											className="flex flex-1"
											initial={{
												opacity: 0,
												scaleX: 0.75,
												y: "1rem",
												display: "none",
											}}
											animate={{
												opacity: 1,
												scaleX: 1,
												y: 0,
												display: "flex",
											}}
											transition={{ duration: 0.25, delay: 2 + index * 0.25 }}
											onAnimationComplete={() => contentRef.current?.scrollToBottom()}
										>
											<IonCheckbox
												key={index}
												className={cx(
													"flex-1 rounded-xl bg-brown-100 p-3 part-[label]:flex part-[label]:flex-1 part-[label]:items-center part-[label]:justify-between part-[label]:gap-2",
													alreadyInList && "part-container:opacity-50",
													!coreValue?.id && "hidden",
												)}
												checked={checked}
												disabled={alreadyInList || !coreValue?.id}
												value={coreValue?.id}
												onIonChange={(event) =>
													setSelectedValues((values) => {
														const value = String(event.detail.value);
														if (values.includes(value)) {
															return values.filter((v) => v !== value);
														} else {
															return [...values, value];
														}
													})
												}
											>
												<span className="flex flex-row gap-2 font-semibold text-brown-700">
													<IonText className="block min-w-5 text-center">
														{coreValue?.emoji || "❓"}
													</IonText>
													<IonText>{coreValue?.name || coreValue?.id || ""}</IonText>
												</span>

												{!coreValue.id && (
													<IonText className="text-end text-xs font-normal text-brown-400">
														{t("modal.friend-check.analysis.generate.core-values.invalid-in-list")}
													</IonText>
												)}

												{alreadyInList && (
													<IonText className="text-end text-xs font-normal text-brown-400">
														{t("modal.friend-check.analysis.generate.core-values.already-in-list")}
													</IonText>
												)}
											</IonCheckbox>
										</motion.div>
									);
								})}
							</div>
						)}
					</motion.div>

					<MotionButton
						className="mt-auto text-lg font-bold"
						onClick={() => {
							if (selectedValues.length === 0) {
								modalRef.current?.dismiss();
							} else {
								modalConfirmRef.current?.present();
							}
						}}
						initial="hidden"
						animate={
							querySummaryStream.isFetched &&
							queryCoreValuesStream.isFetched &&
							queryAllCoreValues.isFetched
								? "visible"
								: "hidden"
						}
						variants={{
							visible: { opacity: 1, display: "flex" },
							hidden: { opacity: 0, display: "none" },
						}}
						transition={{ delay: 2, duration: 0.5 }}
					>
						{t("modal.friend-check.analysis.generate.confirm.trigger", {
							count: selectedValues.length,
						})}
					</MotionButton>
				</Content>
			</IonModal>

			<IonModal
				ref={modalConfirmRef}
				initialBreakpoint={1}
				breakpoints={[0, 1]}
				className="ion-h-auto"
			>
				<div className="ion-padding flex flex-col gap-8 pb-8 pt-12">
					<div className="flex flex-col gap-2">
						<IonText className="text-2xl font-bold text-brown-700">
							{t("modal.friend-check.analysis.generate.confirm.title")}
						</IonText>
						<IonText className="text-base font-normal text-brown-600">
							{t("modal.friend-check.analysis.generate.confirm.description", {
								count: selectedValues.length,
							})}
						</IonText>
					</div>
					<div className="flex flex-col gap-3">
						{coreValues
							?.filter((coreValue) => selectedValues.includes(String(coreValue.id)))
							.map((coreValue, index) => {
								return (
									<IonCheckbox
										key={index}
										className="rounded-xl bg-brown-100 p-3 opacity-100 part-[label]:flex part-[label]:flex-1 part-[label]:items-center part-[label]:justify-between part-[label]:gap-2"
										checked
										disabled
										value={coreValue?.id}
									>
										<span className="flex flex-row gap-2 font-semibold text-brown-700">
											<IonText className="block min-w-5 text-center">
												{coreValue?.emoji || "❓"}
											</IonText>
											<IonText>{coreValue?.name || coreValue?.id || ""}</IonText>
										</span>
									</IonCheckbox>
								);
							})}
					</div>
					<div className="flex flex-col gap-2 *:rounded-2xl *:text-lg *:font-bold">
						<Button
							onClick={() => {
								mutation.mutate(
									(draft) => void (draft.coreValues.savedFriendCheck = selectedValues),
								);
								modalConfirmRef.current?.dismiss();
								modalRef.current?.dismiss();
							}}
						>
							{t("modal.friend-check.analysis.generate.confirm.actions.confirm")}
						</Button>
						<Button
							fill="clear"
							color="secondary"
							onClick={() => modalConfirmRef.current?.dismiss()}
						>
							{t("modal.friend-check.analysis.generate.confirm.actions.dismiss")}
						</Button>
					</div>
				</div>
			</IonModal>
		</>
	);
}
