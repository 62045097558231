import posthog from "posthog-js";
import { env } from "@/lib/env";
import { debugValue } from "@/lib/env/utils";
import { SentryReact } from "@/lib/sentry";

if (env.VITE_POSTHOG_KEY) {
	posthog.init(env.VITE_POSTHOG_KEY, {
		api_host: env.VITE_POSTHOG_HOST || "https://eu.i.posthog.com",
		person_profiles: "always",
		persistence: "localStorage",
		loaded: (posthog) => {
			if (debugValue("posthog")) {
				posthog.debug();
			}
		},
		enable_recording_console_log: true,
		enable_heatmaps: true,
		session_recording: {
			maskAllInputs: true,
			maskTextSelector: "[data-ph-no-capture]",
		},
		sanitize_properties: (properties) => {
			if (properties["$current_url"]) {
				properties["$current_url"] = maskedSensitiveParams(properties["$current_url"]);
			}
			return properties;
		},
	});

	if (!!env.VITE_SENTRY_PROJECT_ID && !!env.VITE_SENTRY_ORG && !!env.VITE_SENTRY_URL) {
		SentryReact.addIntegration(
			new posthog.SentryIntegration(
				posthog,
				env.VITE_SENTRY_ORG,
				env.VITE_SENTRY_PROJECT_ID,
				`${env.VITE_SENTRY_URL}/organizations/`,
			),
		);
	}
}

export { posthog };

function maskedSensitiveParams(url: string) {
	// Short circuit if no params are detected
	if (url.indexOf("?") === -1) {
		return url;
	}

	const queryString = url.split("?")[1];
	const searchParams = new URLSearchParams(queryString);

	// Sensitive parameters to be hidden
	const sensitiveParams = ["accessToken", "refreshToken", "verificationCode", "email"];

	// Replace each sensitive parameter's value with '{hidden}'
	sensitiveParams.forEach((param) => {
		if (searchParams.has(param)) {
			searchParams.set(param, "{hidden}");
		}
	});

	// Combine the base URL with the modified query string
	return url.split("?")[0] + "?" + searchParams.toString();
}
