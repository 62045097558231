import { useDeferredValue, useMemo, useState } from "react";
import {
	type DefaultError,
	type QueryFunctionContext,
	type QueryKey,
	type UseQueryOptions,
	useQuery,
} from "@tanstack/react-query";
import { ContentType, type HttpResponse, type RequestParams } from "@/lib/backend/api";
import { events } from "@/utils/event-stream";

export function useQueryStream<TQueryKey extends QueryKey>({
	fn,
	...options
}: Omit<UseQueryOptions<Promise<string>, DefaultError, Promise<string>, TQueryKey>, "queryFn"> & {
	fn: (
		params: RequestParams,
		context: QueryFunctionContext<TQueryKey>,
	) => Promise<HttpResponse<any, any>>;
}) {
	const [result, setResult] = useState<string | null>(null);
	const deferredResult = useDeferredValue(result);

	const query = useQuery<Promise<string>, DefaultError, Promise<string>, TQueryKey>({
		enabled: false,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
		queryFn: async (context) => {
			const response = await fn(
				{ signal: context.signal, type: ContentType.Stream, format: null },
				context,
			);

			if (!response.ok) {
				throw response.error;
			}

			const stream = events(response, context.signal);

			let value = "";

			for await (const event of stream) {
				value += event.data;
				setResult(value);
			}

			return value;
		},
	});

	return useMemo(
		() => [deferredResult, query] as const,
		[deferredResult, query.status, query.dataUpdatedAt, query.data],
	);
}
