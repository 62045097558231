import { IonIcon, IonModal, IonSkeletonText, IonText, useIonRouter } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { z } from "zod";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { CoreValueRankingRow } from "@/components/CoreValue/RankingRow";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { fadeAnimation } from "@/components/Modal/animations";
import { backendService } from "@/lib/backend";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { query_coreValues } from "@/lib/query/functions/core-values/all";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useQueryStream } from "@/lib/query/hooks/useQueryStream";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";
import { jsonParse } from "@/utils/json/parse";

const MotionButton = motion.create(Button);

export function ModalReflectionAnalysisGenerate() {
	const { t } = useTranslation();
	const router = useIonRouter();
	const contentRef = useRef<HTMLIonContentElement>(null);
	const modalRevealRef = useRef<HTMLIonModalElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionAnalysisGenerate();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, queryMyProgression] = useMyProgression();

	const queryAllCoreValues = useQuery({ ...query_coreValues, enabled: modal.isOpen });

	const [streamedCoreValues, queryStream] = useQueryStream({
		queryKey: ["core-values", "reflection", "analysis-stream"],
		fn: (params) =>
			backendService.corevalues.coreValuesControllerGenerateCoreValueAnalysisStream(
				{ type: "reflection" },
				params,
			),
	});

	const parsedCoreValues = jsonParse({
		input: streamedCoreValues || "[]",
		schema: z.array(
			z
				.object({
					name: z.string().nullish(),
					score: z.coerce.number().nullish(),
					reason: z.string().nullish(),
				})
				.passthrough(),
		),
	});

	const coreValues = parsedCoreValues
		?.filter((coreValue) => queryAllCoreValues.data?.some((value) => value.name === coreValue.name))
		.map((coreValue) => ({
			...coreValue,
			// Enrich with actual emojis and colors
			...queryAllCoreValues.data?.find((value) => value.name === coreValue.name),
		}));

	const isBusy = queryStream.isFetching;
	const hasCompleted = !isBusy && queryStream.isFetched;
	const hasValue = !!parsedCoreValues && parsedCoreValues.length > 0;
	const hasInvalidCompleted = hasCompleted && !hasValue;

	return (
		<>
			<IonModal
				ref={modalRevealRef}
				isOpen={modal.isOpen}
				onWillPresent={() => present("reflectionAnalysisGenerate")}
				onWillDismiss={() => modalRef.current?.present()}
				onClick={() => modalRevealRef.current?.dismiss()}
				enterAnimation={(baseEl) => fadeAnimation(baseEl).direction("normal")}
				leaveAnimation={(baseEl) => fadeAnimation(baseEl).direction("reverse")}
				className="cursor-pointer !ion-rounded-none ion-h-full ion-w-full"
			>
				<Content
					fullscreen
					scrollY={false}
					className="part-background:bg-gradient-to-b part-background:from-orange-500 part-background:to-[#FF944C] part-scroll:items-center part-scroll:justify-center part-scroll:gap-3 part-scroll:text-center part-scroll:text-white"
				>
					<IonText className="text-4xl font-bold">
						{t("modal.reflection.analysis.generate.title")}
					</IonText>
					<IonText className="font-semibold text-gray-100">
						{t("modal.reflection.analysis.generate.text")}
					</IonText>
				</Content>
			</IonModal>

			<IonModal
				ref={modalRef}
				onWillDismiss={() => dismiss("reflectionAnalysisGenerate")}
				onDidPresent={() => queryStream.refetch()}
				onDidDismiss={() => {
					router.push(Routes.CoreValues());
					queryMyProgression.refetch();

					setTimeout(() => {
						queryMyProgression.refetch();
					}, 500);
				}}
				backdropDismiss={false}
			>
				<Content
					ref={contentRef}
					fullscreen
					inModal
					className={cx(
						"part-scroll:gap-4",
						queryStream.isPending &&
							"last:*:snap-end last:*:scroll-mb-4 part-scroll:snap-y part-scroll:snap-proximity",
					)}
				>
					<CoreValueAvatarWithBackground streamed coreValues={coreValues} />

					<IonText className="text-pretty px-4 py-2 text-center text-brown-700">
						{t("modal.reflection.analysis.view.text")}
					</IonText>

					{isBusy &&
						!hasValue &&
						Array.from({ length: 3 }).map((_, index) => (
							<IonSkeletonText key={index} animated className="h-12 rounded-xl" />
						))}

					<MotionButton
						initial="hidden"
						animate={hasInvalidCompleted ? "visible" : "hidden"}
						variants={{
							visible: { opacity: 1, display: "block" },
							hidden: { opacity: 0, display: "none" },
						}}
						onClick={() => queryStream.refetch()}
					>
						{t("try-again")}
						<IonIcon slot="end" icon={arrowPathIcon} className="size-4" />
					</MotionButton>

					<ol className="flex flex-1 flex-col gap-3">
						{coreValues?.map((coreValue, index) => (
							<CoreValueRankingRow
								key={index}
								data={{ ...coreValue, number: index + 1 }}
								transition={{ duration: 0.25, delay: index * 0.25 }}
								onAnimationComplete={() => contentRef.current?.scrollToBottom()}
								onClick={() => present("coreValuesDetail", { item: coreValue })}
							/>
						))}
					</ol>

					<MotionButton
						className="mt-auto text-lg font-bold"
						onClick={() => modalRef.current?.dismiss()}
						initial="hidden"
						animate={hasCompleted && !hasInvalidCompleted ? "visible" : "hidden"}
						variants={{
							visible: { opacity: 1, pointerEvents: "auto" },
							hidden: { opacity: 0, pointerEvents: "none" },
						}}
						transition={{ delay: 3.5, duration: 0.5 }}
					>
						{t("modal.reflection.analysis.generate.next")}
					</MotionButton>
				</Content>
			</IonModal>
		</>
	);
}
