import { createContext, useContext } from "react";
import {
	type ExperimentKey,
	useExperimentKey,
	useFeatureFlagEnabled,
} from "@/lib/posthog/config/utils";

/** This config should be retrieved from PostHog */
export type Config = {
	development: boolean;
	friendCheckAppStoreLink: ExperimentKey<"custom">;
	friendCheckEmail: ExperimentKey<"patch">;
};

export const ConfigContext = createContext<Config>({
	development: false,
	friendCheckAppStoreLink: "control",
	friendCheckEmail: "control",
});

export function useConfig() {
	const config = useContext(ConfigContext);

	if (!config) {
		throw new Error("useConfig must be used within a ConfigContext");
	}

	return config;
}

export function ConfigProvider(
	props: Omit<React.ComponentProps<typeof ConfigContext.Provider>, "value">,
) {
	const development = useFeatureFlagEnabled("development");
	const friendCheckAppStoreLink = useExperimentKey("friend-check-app-store-link", ["custom"]);
	const friendCheckEmail = useExperimentKey("friend-check-email", ["patch"]);

	return (
		<ConfigContext.Provider
			{...props}
			value={{
				development: process.env.NODE_ENV === "development" || development,
				friendCheckAppStoreLink,
				friendCheckEmail,
			}}
		/>
	);
}
