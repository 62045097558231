import { pino } from "pino";
import { env } from "@/lib/env";
import { debugValue } from "@/lib/env/utils";

export const logger = pino({
	level: env.VITE_LOG_LEVEL,
	...(debugValue("logger") && {
		transport: {
			target: "pino-pretty",
			options: {
				colorize: true,
			},
		},
	}),
	browser: {
		asObject: true,
	},
});
