import {
	SplashScreen as CapacitorSplashScreen,
	type HideOptions,
	type ShowOptions,
} from "@capacitor/splash-screen";

export const SplashScreen = (function () {
	async function show(options?: ShowOptions) {
		return await CapacitorSplashScreen.show(options);
	}

	async function hide(options?: HideOptions) {
		return await CapacitorSplashScreen.hide(options);
	}

	return {
		...CapacitorSplashScreen,
		show,
		hide,
	};
})();

export type * from "@capacitor/splash-screen";
