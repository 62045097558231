import {
	type AlertOptions,
	Dialog as CapacitorDialog,
	type ConfirmOptions,
	type PromptOptions,
} from "@capacitor/dialog";

export const Dialog = (function () {
	async function alert(options: AlertOptions) {
		return await CapacitorDialog.alert(options);
	}

	async function prompt(options: PromptOptions) {
		return await CapacitorDialog.prompt(options);
	}

	async function confirm(options: ConfirmOptions) {
		return (await CapacitorDialog.confirm(options)).value;
	}

	return {
		...CapacitorDialog,
		alert,
		prompt,
		confirm,
	};
})();

export type * from "@capacitor/dialog";
