import { NotificationListenerLocal } from "@/components/Global/NotificationListener/Local";
import { NotificationListenerPush } from "@/components/Global/NotificationListener/Push";

export function NotificationListener() {
	return (
		<>
			<NotificationListenerLocal />
			<NotificationListenerPush />
		</>
	);
}
