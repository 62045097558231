import { IonButtons, IonFooter, IonIcon, IonModal, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { usePostHog } from "posthog-js/react";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalReflectionExplainerFirstQuestion() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionExplainerFirstQuestion();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	const query = useQuery({
		queryKey: ["reflection", modal.id!] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
					signal: context.signal,
				}),
			),
		enabled: modal.isOpen && !!modal.id,
	});

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("reflectionExplainerFirstQuestion")}
			onWillDismiss={() => dismiss("reflectionExplainerFirstQuestion", { id: null })}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-2 pt-12">
				<div className="flex flex-col gap-1">
					<IonText className="text-2xl font-bold text-brown-700">
						{t("modal.reflection.explainer.first-question.title")}
					</IonText>
					<IonText className="text-brown-600">
						{t("modal.reflection.explainer.first-question.subtitle")}
					</IonText>
				</div>

				{!!query.data?.question && (
					<ReflectionCard className="rounded-xl bg-white p-3 text-base font-semibold text-brown-600">
						{query.data.question}
					</ReflectionCard>
				)}
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<IonButtons slot="primary">
						<Button
							fill="solid"
							className="!ion-rounded-2xl !ion-px-7 !ion-py-3 part-native:text-lg part-native:font-bold"
							onClick={() => {
								posthog.capture("Explainer Reflection First Question Finished");
								modalRef.current?.dismiss();
								present("reflectionQuestionFirstAnswer", { id: modal.id });
							}}
						>
							{t("modal.reflection.explainer.first-question.next")}
							<IonIcon slot="end" icon={arrowRightIcon} className="size-6" />
						</Button>
					</IonButtons>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
