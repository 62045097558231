export function hasAnswer(
	answer?: string | string[] | { content?: string; options?: string[] },
): boolean {
	if (typeof answer === "string") {
		return !!answer;
	}

	if (Array.isArray(answer)) {
		return answer.length > 0;
	}

	if (answer?.content && answer?.content !== "") {
		return hasAnswer(answer?.content);
	}

	if (answer?.options && answer.options.length > 0) {
		return hasAnswer(answer.options);
	}

	return false;
}

export function getAnswer(
	answer: string | string[] | { content?: string; options?: string[] },
	{ language }: { language: string },
) {
	if (typeof answer === "string") {
		return answer;
	}

	if (Array.isArray(answer)) {
		return new Intl.ListFormat(language).format(answer);
	}

	if (answer?.content && answer?.content !== "") {
		return getAnswer(answer?.content, { language });
	}

	if (answer?.options && answer.options.length > 0) {
		return getAnswer(answer.options, { language });
	}

	return "";
}
