import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import ICU from "i18next-icu";
import { debugValue } from "@/lib/env/utils";
import nl from "./locales/nl.json";

export const DEFAULT_LANGUAGE = "nl";
export const LANGUAGES = [DEFAULT_LANGUAGE] as const;

export const resources = { nl };

i18n
	.use(ICU)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		fallbackLng: DEFAULT_LANGUAGE,
		// lng: defaultLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
		// you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
		// if you're using a language detector, do not define the lng option

		defaultNS: "common",
		ns: "common",

		interpolation: {
			prefix: "{",
			suffix: "}",
			escapeValue: false, // react already safes from xss
		},

		debug: debugValue("i18n"),
	});

export { i18n };
