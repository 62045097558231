import { Device as CapacitorDevice } from "@capacitor/device";

export const Device = (function () {
	async function getId() {
		return (await CapacitorDevice.getId()).identifier;
	}

	async function getInfo() {
		return await CapacitorDevice.getInfo();
	}

	async function getBatteryInfo() {
		return await CapacitorDevice.getBatteryInfo();
	}

	async function getLanguageCode() {
		return await CapacitorDevice.getLanguageCode();
	}

	async function getLanguageTag() {
		return await CapacitorDevice.getLanguageTag();
	}

	return {
		...CapacitorDevice,
		getId,
		getInfo,
		getBatteryInfo,
		getLanguageCode,
		getLanguageTag,
	};
})();

export type * from "@capacitor/device";
