/**
 * Polyfill for crypto.randomUUID
 *
 * @lends https://github.com/ungap/random-uuid/blob/main/index.js
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
 */

if (typeof window !== "undefined" && typeof crypto.randomUUID !== "function") {
	crypto.randomUUID = function () {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore Valid JavaScript below
		const value = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11) as string;
		const replacer = (character: string) => {
			const number = Number(character);
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			const randomValue = crypto.getRandomValues(new Uint8Array(1))[0]!;
			return (number ^ (randomValue & (15 >> (number / 4)))).toString(16);
		};

		return value.replace(/[018]/g, replacer) as `${string}-${string}-${string}-${string}-${string}`;
	};
}
