import {
	IonBackButton,
	IonButtons,
	IonChip,
	IonFooter,
	IonHeader,
	IonIcon,
	IonPage,
	IonSpinner,
	useIonRouter,
} from "@ionic/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { debounce, isEqual } from "lodash-es";
import { z } from "zod";
import { CoreValueBadge } from "@/components/CoreValue/Badge";
import { FormTextarea } from "@/components/Form/Textarea";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionCard } from "@/components/Reflection/Card";
import { ReflectionChatFollowUpQuestion } from "@/components/Reflection/Chat/FollowUpQuestion";
import { backendService } from "@/lib/backend";
import { type ReflectionQuestionTree } from "@/lib/backend/api";
import { type MixedPostReflectionAnswer, type PostReflectionAnswer } from "@/lib/backend/types";
import {
	extractionWrapper,
	getPostReflectionAnswer,
	useExtractedMutation,
} from "@/lib/backend/utils";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowPathIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { paperAirplaneIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { query_reflection } from "@/lib/query/functions/reflection/all";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

const MotionIonChip = motion.create(IonChip);

export function ReflectionQuestion() {
	const { t, i18n } = useTranslation();
	const router = useIonRouter();
	const textareaRef = useRef<HTMLIonTextareaElement>(null);
	const contentRef = useRef<HTMLIonContentElement>(null);
	const PreferredHaptics = usePreferredHaptics();
	const searchParams = Routes.ReflectionQuestion.useParams();
	const queryClient = useQueryClient();
	const [myData, { mutation: mutationMyData }] = useMyData();
	const query = useQuery(query_reflection);
	const presentModal = useSelectorModalStore.use.present();

	const mutation = useExtractedMutation({
		mutationKey: ["reflection", "answer"],
		fn: ({ id, ...variables }: { id: string } & PostReflectionAnswer) =>
			backendService.reflection.reflectionControllerAnswerQuestion(id, variables, {
				format: "text",
			}),
	});
	const mutationFinalize = useExtractedMutation({
		mutationKey: ["reflection", "finalize"],
		fn: (id: string) =>
			backendService.reflection.reflectionControllerFinalizeQuestion(id, { format: "text" }),
	});

	const queryQuestion = useQuery({
		queryKey: ["reflection", searchParams.id!] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.reflection.reflectionControllerGetReflectionById(context.queryKey[1], {
					signal: context.signal,
				}),
			),
		enabled: !!searchParams.id,
	});
	const queryReflection = useQuery(query_reflection);
	const reflectionSummary = queryReflection.data?.find(
		(reflectionSummary) => reflectionSummary.id === queryQuestion.data?.id,
	);

	useEffect(() => {
		if (
			router.routeInfo.pathname === Routes.ReflectionQuestion() &&
			(!searchParams.id || (!queryQuestion.isLoading && !queryQuestion.data))
		) {
			if (router.canGoBack()) {
				router.goBack();
			} else {
				router.push(Routes.Reflection(), "root", "replace");
			}
		}
	}, [searchParams.id, queryQuestion.isLoading, queryQuestion.data]);

	const id = searchParams.id!;
	const question = queryQuestion.data!;

	function getCurrentQuestion(current: ReflectionQuestionTree | null) {
		if (current && current.answers.length > 0) {
			return getCurrentQuestion(current.followUpQuestion);
		}

		return current;
	}

	const currentQuestion = getCurrentQuestion(question);
	const questionTreeId = currentQuestion?.id ?? question?.id ?? id ?? "";
	const initialValues = myData.reflection.questions[questionTreeId];

	const form = useZodForm<Omit<MixedPostReflectionAnswer, "questionType">>({
		defaultValues: initialValues ?? { content: "", options: [] },
		onSubmit: ({ value, formApi }) =>
			mutation.mutateAsync(
				{
					id: questionTreeId,
					...getPostReflectionAnswer({
						questionType: currentQuestion?.questionType ?? "TEXT",
						...value,
					}),
				},
				{
					onSuccess: async (_, variables) => {
						mutationMyData.mutate((draft) => void delete draft.reflection.questions[variables.id]);

						formApi.reset();
						queryClient.invalidateQueries({ queryKey: ["reflection"] });
						query.refetch().then((result) => {
							contentRef.current?.scrollToBottom();

							if (result.data?.every((question) => question.isCompleted)) {
								mutationFinalize.mutate(question.id);
								presentModal("reflectionCompleted");
							} else if (!!currentQuestion && !currentQuestion.followUpQuestion) {
								presentModal("reflectionQuestionCompleted", { id: question.id });
							}
						});
					},
				},
			),
	});

	useEffect(
		() =>
			form.store.subscribe(
				debounce(() => {
					// FUTURE: This fires twice on change, no big deal, but not super nice
					if (
						!!questionTreeId &&
						form.store.state.isDirty &&
						!isEqual(form.store.state.values, initialValues)
					) {
						mutationMyData.mutate(
							(draft) =>
								void (draft.reflection.questions[questionTreeId] = form.store.state.values),
						);
					}
				}, 250),
			),
		[form.store, questionTreeId, JSON.stringify(initialValues)],
	);

	useEffect(() => {
		if (currentQuestion) {
			contentRef.current?.scrollToBottom();
		}
	}, [contentRef.current, currentQuestion]);

	if (!questionTreeId || !question) {
		return <></>;
	}

	return (
		<IonPage>
			<IonHeader className="ion-no-border">
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonButtons slot="secondary" onClick={() => PreferredHaptics.impact()}>
						<IonBackButton
							defaultHref={Routes.Reflection()}
							color="light"
							className="touch-target min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>

			<Content
				ref={contentRef}
				className={cx(
					"part-scroll:gap-6",
					!!currentQuestion &&
						"last:*:snap-end last:*:scroll-mb-4 part-scroll:snap-y part-scroll:snap-proximity",
				)}
			>
				<ReflectionCard
					onClick={() => presentModal("reflectionQuestionCompleted", { id: question.id })}
					className="shrink-0 gap-2"
				>
					<strong className="text-pretty pt-2 text-base font-semibold">{question.question}</strong>
					{question.answers.map((answer) => {
						switch (answer.questionType) {
							case "MULTIPLE_CHOICE":
								return (
									<p key={answer.id} data-ph-no-catpure className="text-base font-normal">
										{new Intl.ListFormat(i18n.language).format(answer.options)}
									</p>
								);
							case "NUMBER":
							case "DATA":
							case "EMAIL":
							case "TEXT":
							default:
								return (
									<p key={answer.id} data-ph-no-capture className="text-base font-normal">
										{answer.content}
									</p>
								);
						}
					})}
					{!currentQuestion && (
						<div className="flex flex-wrap gap-1">
							{!!reflectionSummary &&
							!!reflectionSummary.coreValues &&
							reflectionSummary.coreValues.length > 0 ? (
								reflectionSummary.coreValues.map((coreValue) => (
									<CoreValueBadge key={coreValue.id} value={coreValue} />
								))
							) : (
								<IonChip
									className="m-0 min-h-0 bg-brown-200 py-1 text-brown-700"
									onClick={() => {
										PreferredHaptics.impact();
										presentModal("reflectionQuestionCompleted", { id: question.id });
									}}
								>
									{t("reflection.core-values-generation.title")}
									<IonIcon icon={arrowPathIcon} className="size-4" />
								</IonChip>
							)}
						</div>
					)}
				</ReflectionCard>

				{!!question.followUpQuestion && (
					<ReflectionChatFollowUpQuestion followUpQuestion={question?.followUpQuestion} />
				)}
				<MotionIonChip
					initial={
						currentQuestion ? { opacity: 0, display: "none" } : { opacity: 1, display: "block" }
					}
					animate={
						currentQuestion ? { opacity: 0, display: "none" } : { opacity: 1, display: "block" }
					}
					className="pointer-events-none m-0 mx-auto min-h-0 font-semibold ion-bg-brown-100 ion-text-brown-700 ion-py-1"
				>
					{t("reflection.end-of-question")}
				</MotionIonChip>
			</Content>

			{!!currentQuestion && (
				<IonFooter className="ion-no-border">
					<Toolbar className="ion-bg-brown-200 ion-p-4 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
						{(function () {
							switch (question.questionType) {
								case "MULTIPLE_CHOICE":
								case "NUMBER":
								case "TEXT":
								case "EMAIL":
								case "DATA":
								default:
									return (
										<form
											onSubmit={(event) => {
												event.preventDefault();
												event.stopPropagation();
												void form.handleSubmit();
											}}
											className={cx(
												"relative flex flex-1 flex-row items-end justify-between gap-2 rounded-2xl rounded-br-3xl border-2 border-brown-200 bg-brown-100",
												!currentQuestion && "opacity-60",
											)}
										>
											<form.Field
												name="content"
												validators={{
													onSubmit: z.string().min(3, t("fields.answer.errors.min", { count: 3 })),
												}}
												children={(field) => (
													<FormTextarea
														ref={textareaRef}
														field={field}
														value={field.state.value}
														placeholder={t("fields.answer.placeholder")}
														inputMode="text"
														autoCapitalize="on"
														autoGrow
														autofocusOnIonViewDidEnter
														onKeyDown={async (event) => {
															if (event.metaKey && event.code === "Enter") {
																field.handleChange(
																	String(
																		(event.target as unknown as { value: string })?.value ?? "",
																	),
																);

																void form.handleSubmit();
															}
														}}
														className="flex flex-1 flex-col text-brown-700 [&_.textarea-bottom]:py-1 [&_.textarea-bottom]:ion-px-3 [&_.textarea-wrapper-inner]:!max-h-80 [&_textarea]:!overflow-auto"
													/>
												)}
											/>

											<form.Subscribe
												selector={(state) => [state.canSubmit, state.isSubmitting]}
												children={([canSubmit, isSubmitting]) => (
													<Button
														type="submit"
														disabled={!canSubmit}
														shape="round"
														color="primary"
														size="default"
														className="touch-target min-h-0 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:p-1 part-native:text-sm"
													>
														{isSubmitting ? (
															<IonSpinner slot="icon-only" />
														) : (
															<IonIcon
																slot="icon-only"
																icon={paperAirplaneIcon}
																className="size-3/5"
															/>
														)}
													</Button>
												)}
											/>
										</form>
									);
							}
						})()}
					</Toolbar>
				</IonFooter>
			)}
		</IonPage>
	);
}
