import { type UseMutationOptions, useMutation } from "@tanstack/react-query";
import { type ArrayOrSingle } from "ts-essentials";
import { z } from "zod";
import {
	type HttpResponse,
	type PostReflectionAnswerChoice,
	type PostReflectionAnswerRegular,
} from "@/lib/backend/api";
import { type MixedPostReflectionAnswer, type PostReflectionAnswer } from "@/lib/backend/types";
import { getArrayOrSingle } from "@/utils/functions/getArrayOrSingle";

export const ResponseError = z.object({
	error: z.string().optional(),
	statusCode: z.coerce.number(),
	message: z
		.custom<
			ArrayOrSingle<string>
		>((data) => typeof data === "string" || (Array.isArray(data) && data.every((item) => typeof item === "string")))
		.transform(getArrayOrSingle),
});
export type ResponseError = z.infer<typeof ResponseError>;

export const ResponseWithError = z.object({ error: ResponseError });
export type ResponseWithError = z.infer<typeof ResponseWithError>;

export type ExtractedResponseError = ResponseError | Error;

export function extractResponseData<TData, TError>(response: HttpResponse<TData, TError>) {
	if (response.error) {
		throw response.error;
	}

	return response.data;
}

export function extractResponseError(error: unknown) {
	const responseWithError = ResponseWithError.safeParse(error);
	if (responseWithError.success) {
		throw responseWithError.data.error;
	}

	const responseError = ResponseError.safeParse(error);
	if (responseError.success) {
		throw responseError.data;
	}

	if (error instanceof Error) {
		throw error;
	}

	throw error;
}

export async function extractionWrapper<TData, TError>(call: Promise<HttpResponse<TData, TError>>) {
	try {
		const response = await call;

		return extractResponseData<TData, TError>(response);
	} catch (error) {
		throw extractResponseError(error);
	}
}

export function hasFailedForAIReason<TError = Error>(error: TError) {
	const responseError = ResponseError.safeParse(error);

	if (!responseError.success) {
		return false;
	}

	const { statusCode, error: errorName, message } = responseError.data;

	return (
		statusCode === 500 &&
		errorName === "Internal Server Error" &&
		message.some(
			(message) => message === "Request failed because of a wrong AI answer. Please try again!",
		)
	);
}

export function getPostReflectionAnswer(mixed: MixedPostReflectionAnswer): PostReflectionAnswer {
	switch (mixed.questionType) {
		case "MULTIPLE_CHOICE":
			return {
				questionType: mixed.questionType,
				options: mixed.options,
			} satisfies PostReflectionAnswerChoice;
		case "DATA":
		case "TEXT":
		case "NUMBER":
		case "EMAIL":
		default:
			return {
				questionType: mixed.questionType,
				content: mixed.content,
			} satisfies PostReflectionAnswerRegular;
	}
}

export function useExtractedMutation<
	TData = unknown,
	TError extends ExtractedResponseError = ExtractedResponseError,
	TVariables = unknown,
	TContext = unknown,
>({
	fn,
	...options
}: {
	fn: (variables: TVariables) => Promise<HttpResponse<TData, any>>;
} & Omit<UseMutationOptions<TData, TError, TVariables, TContext>, "mutationFn">) {
	return useMutation<TData, TError, TVariables, TContext>({
		...options,
		mutationFn: (variables) => extractionWrapper<TData, TError>(fn(variables)),
	});
}
