import { useTranslation } from "react-i18next";
import { Button } from "@/components/Global/Button";
import { cx } from "@/lib/style/cva.config";

export function SocialButtonGoogle({
	label = "continue",
	...props
}: React.ComponentProps<typeof Button> & { label?: "continue" | "sign-in" | "sign-up" }) {
	const { t } = useTranslation();

	return (
		<Button
			{...props}
			className={cx(
				"m-0 inline-flex items-center text-center text-sm font-medium text-white ion-bg-[#4285F4] ion-bg-a-[#4285F4]/90 ion-bg-f-[#4285F4]/90 ion-bg-h-[#4285F4]/90 ion-rounded-lg ion-px-5 ion-py-2.5 dark:ion-bg-a-[#4285F4]/30 dark:ion-bg-f-[#4285F4]/30 dark:ion-bg-h-[#4285F4]/30",
				props.className,
			)}
		>
			<GoogleLogo slot="start" className="-ml-1 mr-2" />
			{t(`auth.social-button.google.${label}`)}
		</Button>
	);
}

export function GoogleLogo(props: React.ComponentPropsWithoutRef<"svg"> & { slot?: string }) {
	return (
		<svg
			{...props}
			className={cx("size-4", props.className)}
			aria-hidden="true"
			focusable="false"
			role="img"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 488 512"
		>
			<path
				fill="currentColor"
				d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
			></path>
		</svg>
	);
}
