import { Content } from "@/components/Global/Content";
import { cx } from "@/lib/style/cva.config";

export function AuthContent({
	parts,
	...props
}: React.ComponentProps<typeof Content> & {
	parts?: { container?: React.ComponentPropsWithoutRef<"div"> };
}) {
	return (
		<Content fullscreen {...props}>
			<div
				{...parts?.container}
				className={cx(
					"mx-auto flex w-full flex-1 flex-col gap-6 plt-desktop:max-w-sm",
					parts?.container?.className,
				)}
			>
				{props.children}
			</div>
		</Content>
	);
}
