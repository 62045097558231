import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { Capacitor } from "@/lib/capacitor";
import { debugValue } from "@/lib/env/utils";

export const useAuthFlowStore = create<
	{
		email: string;
		verificationCode: string;
		password: string;
	} & {
		reset: VoidFunction;
	}
>()(
	devtools(
		(set) => ({
			email: "",
			verificationCode: "",
			password: "",

			reset: () => set({ email: "", verificationCode: "", password: "" }),
		}),
		{
			enabled: debugValue("store") && Capacitor.Platform === "web",
		},
	),
);
