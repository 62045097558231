import { z } from "zod";
import { makeRoute } from "@/lib/router/utils";

export const Routes = {
	App: makeRoute("/app"),
	Dashboard: makeRoute("/app/dashboard"),
	Reflection: makeRoute("/app/reflection"),
	FriendCheck: makeRoute("/app/friend-check"),
	CoreValues: makeRoute("/app/core-values"),

	FriendCheckSubmission: makeRoute(
		"/friend-check/submission",
		z.object({ id: z.string().optional() }),
	),
	ReflectionQuestion: makeRoute("/reflection/question", z.object({ id: z.string().optional() })),

	Settings: makeRoute("/settings"),
	Profile: makeRoute("/settings/profile"),

	SharedFriendCheck: makeRoute(
		"/share/friend-check",
		z.object({ id: z.string().optional(), hash: z.string().optional() }),
	),
	SharedFriendCheckQuestions: makeRoute("/share/friend-check/questions"),
	SharedFriendCheckThankYou: makeRoute("/share/friend-check/thank-you"),

	Auth: makeRoute("/auth"),

	AuthSignIn: makeRoute("/auth/sign-in"),
	AuthSignInPassword: makeRoute("/auth/sign-in/password"),
	AuthSignInMagic: makeRoute(
		"/auth/sign-in/magic",
		z.object({
			error: z
				.union([
					z.literal("google_signin_cancelled"),
					z.literal("apple_signin_cancelled"),
					z.string(),
				])
				.optional(),
		}),
	),
	AuthSignInMagicVerify: makeRoute("/auth/sign-in/magic/verify"),

	AuthSignUp: makeRoute("/auth/sign-up"),
	AuthSignUpVerify: makeRoute("/auth/sign-up/verify"),
	AuthSignUpPassword: makeRoute("/auth/sign-up/password"),
	AuthSignUpProfile: makeRoute("/auth/sign-up/profile"),

	AuthForgotPassword: makeRoute("/auth/forgot-password"),
	AuthForgotPasswordVerify: makeRoute("/auth/forgot-password/verify"),
	AuthForgotPasswordReset: makeRoute("/auth/forgot-password/reset"),

	AuthOAuthRedirect: makeRoute(
		"/auth/oath/redirect",
		z.object({
			accessToken: z.string().optional(),
			refreshToken: z.string().optional(),
			error: z.string().optional(),
		}),
	),

	NoAccess: makeRoute("/no-access", z.object({ path: z.string().optional() })),
};
