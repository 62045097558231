import {
	type BackButtonListener,
	App as CapacitorApp,
	type RestoredListener,
	type StateChangeListener,
	type URLOpenListener,
} from "@capacitor/app";
import { Capacitor } from "@/lib/capacitor";
import { logger } from "@/lib/logger";

export const App = (function () {
	async function exitApp() {
		return await CapacitorApp.exitApp();
	}

	async function getInfo() {
		if (Capacitor.Platform === "web") {
			logger.debug("App.getInfo() is not available on Web");
			return;
		}

		return await CapacitorApp.getInfo();
	}

	async function getState() {
		return (await CapacitorApp.getState()).isActive;
	}

	async function getLaunchUrl() {
		return await CapacitorApp.getLaunchUrl();
	}

	async function minimizeApp() {
		return await CapacitorApp.minimizeApp();
	}

	function addStateChangeListener(listenerFunc: StateChangeListener) {
		return CapacitorApp.addListener("appStateChange", listenerFunc);
	}

	function addUrlOpenListener(listenerFunc: URLOpenListener) {
		return CapacitorApp.addListener("appUrlOpen", listenerFunc);
	}

	function addRestoredResultListener(listenerFunc: RestoredListener) {
		return CapacitorApp.addListener("appRestoredResult", listenerFunc);
	}

	function addBackButtonListener(listenerFunc: BackButtonListener) {
		return CapacitorApp.addListener("backButton", listenerFunc);
	}

	function removeAllListeners() {
		return CapacitorApp.removeAllListeners();
	}

	return {
		...CapacitorApp,
		exitApp,
		getInfo,
		getState,
		getLaunchUrl,
		minimizeApp,
		addStateChangeListener,
		addUrlOpenListener,
		addRestoredResultListener,
		addBackButtonListener,
		removeAllListeners,
	};
})();

export type * from "@capacitor/app";
