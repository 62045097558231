import { type ServerSentEventMessage } from "./deno/http/server_sent_event_stream";
import { TextLineStream } from "./deno/streams/text_line_stream";

/**
 * Convert a `Response` body containing Server Sent Events (SSE) into an Async Iterator that yields
 * {@linkcode ServerSentEventMessage} objects.
 *
 * @example
 * 	```js
 * 	// Optional
 * 	let abort = new AbortController;
 *
 * 	// Manually fetch a Response
 * 	let res = await fetch('https://...', {
 * 	  method: 'POST',
 * 	  signal: abort.signal,
 * 	  headers: {
 * 	    'api-key': 'token <value>',
 * 	    'content-type': 'application/json',
 * 	  },
 * 	  body: JSON.stringify({
 * 	    stream: true, // <- hypothetical
 * 	    // ...
 * 	  })
 * 	});
 *
 * 	if (res.ok) {
 * 	  let stream = events(res, abort.signal);
 * 	  for await (let event of stream) {
 * 	    console.info('<<', event.data);
 * 	  }
 * 	}
 * 	```;
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Server-sent_events}
 */
export async function* events(
	response: Response,
	signal?: AbortSignal | null,
): AsyncGenerator<ServerSentEventMessage, void, unknown> {
	if (!response.body) {
		return;
	}

	const stream = response.body
		.pipeThrough(new TextDecoderStream())
		.pipeThrough(new TextLineStream({ allowCR: true }));

	let line;
	const reader = stream.getReader();
	let event: ServerSentEventMessage | undefined;

	for (;;) {
		if (signal && signal.aborted) {
			return reader.cancel();
		}

		line = await reader.read();
		if (line.done) {
			return;
		}

		if (!line.value) {
			if (event) {
				yield event;
			}
			event = undefined;
			continue;
		}

		const [field, value] = split(line.value) || [];
		if (!field) {
			continue;
		} // comment or invalid

		if (field === "data") {
			event ||= {};
			event[field] = event[field] ? event[field] + "\n" + value : value;
		} else if (field === "event") {
			event ||= {};
			event[field] = value;
		} else if (field === "id") {
			event ||= {};
			event[field] = +value || value;
		} else if (field === "retry") {
			event ||= {};
			event[field] = +value || undefined;
		}
	}
}

function split(input: string) {
	const rgx = /[:]\s/;
	const match = rgx.exec(input);
	// ": comment" -> index=0 -> ignore
	const idx = match && match.index;
	if (idx) {
		return [input.substring(0, idx), input.substring(idx + match![0].length)];
	}
}
