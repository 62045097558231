import { IonSkeletonText, useIonRouter } from "@ionic/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ReflectionChatBubble } from "@/components/Reflection/Chat/Bubble";
import { type ReflectionSummary } from "@/lib/backend/api";
import { getAnswer, hasAnswer } from "@/lib/functions/answer";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { useSelectorModalStore } from "@/lib/store/modal";
import { cx } from "@/lib/style/cva.config";
import { DashboardReflectionWidgetCard as Card } from "./Card";

export function DashboardReflectionWidgetStack({
	isLoading = false,
	data,
	...props
}: React.ComponentPropsWithoutRef<"div"> & { isLoading?: boolean; data: ReflectionSummary[] }) {
	const { i18n } = useTranslation();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const [currentIndex, setCurrentIndex] = useState(0);
	const [myData] = useMyData();
	const presentModal = useSelectorModalStore.use.present();

	const questions = data.filter((question) => !question.isCompleted);

	return (
		<div {...props} className={cx("flex flex-col items-center gap-2", props.className)}>
			<div className="relative isolate h-48 w-full">
				{isLoading
					? Array.from({ length: 3 }).map((_, index) => (
							<IonSkeletonText
								key={index}
								animated
								className="absolute h-[9.5rem] border border-brown-200 bg-brown-100 ion-bg-brown-100 ion-rounded-3xl"
								style={{
									transform: `scaleX(${1 - 0.05 * index})`,
									zIndex: -10 * index,
									bottom: `${1 * index}rem`,
								}}
							/>
						))
					: questions.map((question, index) => {
							const hasFirstAnswer = hasAnswer(question.firstAnswer);
							const hasInProgressAnswer = hasAnswer(myData.reflection.questions[question.id]);
							const hasStarted = question.isCompleted || hasFirstAnswer || hasInProgressAnswer;

							return (
								<Card
									key={question.id}
									reflectionSummary={question}
									index={index}
									currentIndex={currentIndex}
									maxIndex={questions.length}
									handleNext={() => setCurrentIndex((c) => ++c % questions.length)}
									onClick={() => {
										if (
											!data.some(
												(question) =>
													question.isCompleted ||
													hasAnswer(question.firstAnswer) ||
													hasAnswer(myData.reflection.questions[question.id]),
											) &&
											!myData.modal.reflectionExplainerGeneral.seen
										) {
											presentModal("reflectionExplainerGeneral", {
												onCompleted: () =>
													presentModal("reflectionExplainerFirstQuestion", { id: question.id }),
											});
										} else if (hasFirstAnswer) {
											router.push(Routes.ReflectionQuestion({ id: question.id }));
										} else {
											presentModal("reflectionQuestionFirstAnswer", { id: question.id });
										}
									}}
								>
									<strong
										className={cx(
											"line-clamp-4 flex-1 text-pretty text-xl font-semibold",
											hasFirstAnswer && "line-clamp-2",
										)}
									>
										{question.question}
									</strong>
									{hasStarted && (
										<ReflectionChatBubble
											sender="user"
											className={cx(
												"me-0 ms-auto ion-bg-brown-200",
												!hasFirstAnswer && hasInProgressAnswer && "in-progress-background italic",
											)}
											parts={{
												smallBubble: {
													className: cx(
														"-top-3 right-0",
														!hasFirstAnswer &&
															hasInProgressAnswer &&
															"in-progress-background size-2",
													),
												},
												text: {
													// @ts-expect-error Data is not typed
													"data-ph-no-capture": true,
													className: "truncate line-clamp-none px-1 -mx-1",
												},
											}}
										>
											{getAnswer(
												hasFirstAnswer
													? question.firstAnswer
													: myData.reflection.questions[question.id] || "",
												{ language: i18n.language },
											)}
										</ReflectionChatBubble>
									)}
								</Card>
							);
						})}
			</div>

			{isLoading ? (
				<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full">
					{Array.from({ length: 3 }).map((_, index) => (
						<button key={index} className={index === 0 ? "bg-brown-500" : "bg-brown-300"} />
					))}
				</div>
			) : (
				questions.length > 1 && (
					<div className="relative isolate flex w-fit flex-row items-center justify-center gap-2 py-2 *:size-2.5 *:rounded-full">
						{questions.map((question, index) => (
							<button
								key={question.id}
								className="bg-brown-300"
								onClick={() => {
									PreferredHaptics.impact();
									setCurrentIndex(index);
								}}
							/>
						))}
						<motion.div
							className="pointer-events-none absolute left-0 z-10 bg-brown-500"
							initial={{ x: "0rem" }}
							animate={{ x: `${currentIndex * 1.125}rem` }}
							transition={{ type: "spring", duration: 0.3 }}
						/>
					</div>
				)
			)}
		</div>
	);
}
