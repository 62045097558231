/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface SignupDto {
	name?: string;
	family?: string;
	/**
	 * @example
	 * 	2018 - 11 - 21;
	 *
	 * @format date
	 */
	birthday?: string;
	email: string;
	password: string;
	/** @format binary */
	avatar?: File;
	verificationCode: string;
}

export interface RandomReminderSettings {
	enabled?: boolean;
}

export interface EndOfDayReminderSettings {
	enabled?: boolean;
	/**
	 * @example
	 * 	09:30
	 */
	time?: string;
}

export interface UserSettings {
	emailNotification?: boolean;
	pushNotification?: boolean;
	localNotification?: boolean;
	reminderRandom?: RandomReminderSettings;
	reminderEOD?: EndOfDayReminderSettings;
}

export interface User {
	id: string;
	/** @format date-time */
	createdAt: string;
	/** @format date-time */
	updatedAt: string;
	email: string;
	emailVerified: boolean;
	name?: string;
	family?: string;
	avatarURL?: string;
	friendId: string;
	/**
	 * @example
	 * 	2018 - 11 - 21;
	 *
	 * @format date
	 */
	birthday?: string;
	settings?: UserSettings;
	authSource: "MANUAL" | "GOOGLE" | "APPLE";
}

export interface AuthResponse {
	accessToken: string;
	refreshToken: string;
	user: User;
}

export interface SignInDto {
	email: string;
	password: string;
}

export interface EmailVerificationCodeDto {
	email: string;
}

export interface VerifySignupVerificationCodeDto {
	email: string;
	verificationCode: string;
}

export interface VerifyVerificationCodeDto {
	email: string;
	verificationCode: string;
	password?: string;
}

export interface HasAccess {
	hasAccess: boolean;
}

export interface ProgressionStatus {
	reflection: "NOT_STARTED" | "STARTED" | "COMPLETED" | "ANALYSED";
	friend_check: "NOT_STARTED" | "STARTED" | "CLOSED" | "ANALYSED" | "SUMMARIZED";
	core_values: "NOT_STARTED" | "SUMMARIZED";
}

export interface CoreValue {
	title: string;
	frequency: number;
	meanWeight: number;
}

export interface MomentTags {
	id: string;
	moment: Moment;
	momentId: string;
	title: string;
	weight: number;
}

export interface Moment {
	id: string;
	/** @format date-time */
	createdAt: string;
	/** @format date-time */
	updatedAt: string;
	user: User;
	userId: string;
	description: string;
	whyPrompt: string;
	why?: string;
	favorite?: boolean;
	images: MomentImage[];
	tags: MomentTags[];
}

export interface MomentImage {
	id: string;
	moment: Moment;
	momentId: string;
	url: string;
}

export enum SceneBucket {
	Morning = "morning",
	Afternoon = "afternoon",
	Evening = "evening",
}

export interface SceneStoryboard {
	id: string;
	/** @format date-time */
	createdAt: string;
	scene: Scene;
	sceneId: string;
	title: string;
	text: string;
	summary: string;
	prompt: string;
	image: string;
}

export interface Scene {
	id: string;
	/** @format date-time */
	createdAt: string;
	/** @format date-time */
	updatedAt: string;
	user: User;
	userId: string;
	bucket: SceneBucket;
	event: string;
	messages: SceneMessage[];
	storyboards: SceneStoryboard[];
}

export enum SceneMessageRole {
	Assistant = "assistant",
	User = "user",
}

export interface SceneMessage {
	id: string;
	/** @format date-time */
	createdAt: string;
	scene: Scene;
	sceneId: string;
	role: SceneMessageRole;
	content: string;
}

export interface UpdateUserDto {
	name?: string;
	family?: string;
	/**
	 * @example
	 * 	2018 - 11 - 21;
	 *
	 * @format date
	 */
	birthday?: string;
	/** @format binary */
	avatar?: File;
}

export interface UpdateUserSettingsDto {
	emailNotification?: boolean;
	pushNotification?: boolean;
	localNotification?: boolean;
	reminderRandom?: RandomReminderSettings;
	reminderEOD?: EndOfDayReminderSettings;
}

export interface CreateSceneDto {
	bucket: SceneBucket;
	event: string;
}

export interface AnswerMessageDto {
	content: string;
}

export interface SendMessageDto {
	title: string;
	body: string;
}

export interface AddDeviceDto {
	token: string;
	name: string;
}

export interface UserDevice {
	token: string;
	/** @format date-time */
	createdAt: string;
	name: string;
}

export interface CoreValuesAnalysis {
	id: string;
	name: string;
	score: number;
	reason: string;
	color: string;
}

export type Top5CoreValuesDto = object;

export interface CoreValueItem {
	id: string;
	name: string;
	emoji: string;
	color: string;
}

export interface RefineCoreValueDto {
	question: string;
	chosenCoreValueId: string;
}

export interface ChosenCoreValueItem {
	id: string;
	reason: string;
	score: number;
	description: string;
	customName: string;
	coreValue: CoreValueItem;
}

export interface MessageItem {
	id: string;
	/** @format date-time */
	createdAt: string;
	messageRole: string;
	content: string;
}

export interface ChosenCoreValueItemDetail {
	id: string;
	reason: string;
	score: number;
	description: string;
	customName: string;
	coreValue: CoreValueItem;
	messages: MessageItem[];
}

export interface ReflectionAnswerRegular {
	questionType: "NUMBER" | "TEXT" | "EMAIL" | "DATA";
	coreValues?: CoreValueItem[];
	content: string;
	id: string;
}

export interface ReflectionAnswerChoice {
	questionType: "MULTIPLE_CHOICE";
	coreValues?: CoreValueItem[];
	options: string[];
	id: string;
}

export interface PostReflectionAnswerRegular {
	questionType: "NUMBER" | "TEXT" | "EMAIL" | "DATA";
	content: string;
}

export interface PostReflectionAnswerChoice {
	options: string[];
	questionType: "MULTIPLE_CHOICE";
}

export interface ReflectionSummary {
	id: string;
	question: string;
	firstAnswer: string | string[];
	coreValues: CoreValueItem[];
	isCompleted: boolean;
}

export interface ReflectionQuestionTree {
	questionType: "MULTIPLE_CHOICE" | "NUMBER" | "TEXT" | "EMAIL" | "DATA";
	followUpQuestion: ReflectionQuestionTree | null;
	answers: (ReflectionAnswerRegular | ReflectionAnswerChoice)[];
	id: string;
	question: string;
	options: string[];
	selectOptionLimit: number;
}

export interface FriendCheckAnswerPair {
	answer: string | string[];
	followUpQuestion?: FriendCheckAnswerPair | null;
	question: string;
}

export interface FriendCheckSubmission {
	questions: FriendCheckAnswerPair[];
	id: string;
	name: string;
	relation: string;
	email?: string;
	/** @format date-time */
	createdAt?: string;
	seen?: boolean;
}

export interface FriendCheckQuestionTree {
	id: string;
	question: string;
	questionType: "MULTIPLE_CHOICE" | "NUMBER" | "TEXT" | "EMAIL" | "DATA";
	options: string[];
	version: number;
	followUpQuestion: FriendCheckQuestionTree | null;
}

export interface FriendCheck {
	name?: string;
	avatarURL?: string;
	questions?: FriendCheckQuestionTree[];
	friendCheckEnabled?: boolean;
	version: number;
	id: string;
}

export interface FriendcheckQuestionsDto {
	questionId: string;
	answer: string;
}

export interface SubmitFriendcheckDto {
	name: string;
	email?: string;
	relation: string;
	questions: FriendcheckQuestionsDto[];
}

export interface FriendCheckSubmissionResponse {
	submissionId: string;
	patchUntil: string;
	patchToken: string;
}

export interface PatchFriendCheckEmailDto {
	submissionId: string;
	email: string;
	patchToken: string;
}

export interface PatchFriendCheckEnabledDto {
	enabled: boolean;
}

export interface PatchFriendCheckSeenDto {
	friendCheckId: string;
	seen: boolean;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** Set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** Request path */
	path: string;
	/** Content type of request body */
	type?: ContentType;
	/** Query params */
	query?: QueryParamsType;
	/** Format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat | null;
	/** Request body */
	body?: unknown;
	/** Base url */
	baseUrl?: string;
	/** Request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
	Stream = "text/event-stream",
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: "same-origin",
		headers: {},
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
		return keys
			.map((key) =>
				Array.isArray(query[key])
					? this.addArrayQueryParam(query, key)
					: this.addQueryParam(query, key),
			)
			.join("&");
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : "";
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === "object" || typeof input === "string")
				? JSON.stringify(input)
				: input,
		[ContentType.Text]: (input: any) =>
			input !== null && typeof input !== "string" ? JSON.stringify(input) : input,
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				const propertyContent = property instanceof Array ? property : [property];

				for (const formItem of propertyContent) {
					const isFileType = formItem instanceof Blob || formItem instanceof File;
					formData.append(
						key,
						isFileType
							? formItem
							: typeof formItem === "object" && formItem !== null
								? JSON.stringify(formItem)
								: String(formItem),
					);
				}
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
		[ContentType.Stream]: (input: any) => input,
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(
			`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`,
			{
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData && type !== ContentType.Stream
						? { "Content-Type": type }
						: {}),
					...(type && type === ContentType.Stream
						? { Accept: type, "Content-Type": ContentType.Json }
						: {}),
				},
				signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
				body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
			},
		).then(async (response) => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then((data) => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch((e: any) => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @version 0.0.1
 * @title perfect-day-backend
 * @contact
 *
 * Stijlbreuk Perfect Day Backend
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	/**
	 * No description
	 *
	 * @name AppControllerGetInfo
	 * @request GET:/
	 */
	appControllerGetInfo = (params: RequestParams = {}) =>
		this.request<void, any>({
			path: `/`,
			method: "GET",
			...params,
		});

	auth = {
		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerSignup
		 * @request POST:/auth/signup
		 */
		authControllerSignup: (data: SignupDto, params: RequestParams = {}) =>
			this.request<AuthResponse, any>({
				path: `/auth/signup`,
				method: "POST",
				body: data,
				type: ContentType.FormData,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerSignin
		 * @request POST:/auth/signin
		 */
		authControllerSignin: (data: SignInDto, params: RequestParams = {}) =>
			this.request<AuthResponse, any>({
				path: `/auth/signin`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerSendSignupVerificationCode
		 * @request POST:/auth/signup/verification/send
		 */
		authControllerSendSignupVerificationCode: (
			data: EmailVerificationCodeDto,
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/auth/signup/verification/send`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerVerifySignupVerificationCode
		 * @request POST:/auth/signup/verification/verify
		 */
		authControllerVerifySignupVerificationCode: (
			data: VerifySignupVerificationCodeDto,
			params: RequestParams = {},
		) =>
			this.request<boolean, any>({
				path: `/auth/signup/verification/verify`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerSendPasswordResetVerificationCode
		 * @request POST:/auth/password-reset/send
		 */
		authControllerSendPasswordResetVerificationCode: (
			data: EmailVerificationCodeDto,
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/auth/password-reset/send`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerVerifyPasswordResetVerificationCode
		 * @request POST:/auth/password-reset/verify
		 */
		authControllerVerifyPasswordResetVerificationCode: (
			data: VerifySignupVerificationCodeDto,
			params: RequestParams = {},
		) =>
			this.request<boolean, any>({
				path: `/auth/password-reset/verify`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerSendMagicLink
		 * @request POST:/auth/magic
		 */
		authControllerSendMagicLink: (data: EmailVerificationCodeDto, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/auth/magic`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerVerifyMagicLink
		 * @request POST:/auth/magic/verify
		 */
		authControllerVerifyMagicLink: (
			data: VerifySignupVerificationCodeDto,
			params: RequestParams = {},
		) =>
			this.request<AuthResponse, any>({
				path: `/auth/magic/verify`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerResetPassword
		 * @request POST:/auth/password-reset/reset
		 */
		authControllerResetPassword: (data: VerifyVerificationCodeDto, params: RequestParams = {}) =>
			this.request<AuthResponse, any>({
				path: `/auth/password-reset/reset`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerRefresh
		 * @request POST:/auth/refresh
		 * @secure
		 */
		authControllerRefresh: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/auth/refresh`,
				method: "POST",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerGoogleAuth
		 * @request GET:/auth/google
		 */
		authControllerGoogleAuth: (
			query: {
				/**
				 * After login the callback url will call including "access-token" and "refresh-token" as
				 * query params.
				 */
				callbackUrl: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/auth/google`,
				method: "GET",
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerGoogleAuthCallback
		 * @request GET:/auth/google/callback
		 */
		authControllerGoogleAuthCallback: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/auth/google/callback`,
				method: "GET",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerAppleAuth
		 * @request GET:/auth/apple
		 */
		authControllerAppleAuth: (
			query: {
				/**
				 * After login the callback url will call including "access-token" and "refresh-token" as
				 * query params.
				 */
				callbackUrl: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/auth/apple`,
				method: "GET",
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Authentication
		 * @name AuthControllerAppleAuthCallback
		 * @request POST:/auth/apple/callback
		 */
		authControllerAppleAuthCallback: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/auth/apple/callback`,
				method: "POST",
				...params,
			}),
	};
	user = {
		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerMe
		 * @request GET:/user/me
		 * @secure
		 */
		usersControllerMe: (params: RequestParams = {}) =>
			this.request<User, any>({
				path: `/user/me`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerUpdateMe
		 * @request PATCH:/user/me
		 * @secure
		 */
		usersControllerUpdateMe: (data: UpdateUserDto, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/me`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.FormData,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerGetMyAccess
		 * @request GET:/user/me/access
		 * @secure
		 */
		usersControllerGetMyAccess: (params: RequestParams = {}) =>
			this.request<HasAccess, any>({
				path: `/user/me/access`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerGetMyProgressionStatus
		 * @request GET:/user/me/progression
		 * @secure
		 */
		usersControllerGetMyProgressionStatus: (params: RequestParams = {}) =>
			this.request<ProgressionStatus, any>({
				path: `/user/me/progression`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerFindOne
		 * @request GET:/user/{id}
		 * @secure
		 */
		usersControllerFindOne: (id: string, params: RequestParams = {}) =>
			this.request<User, any>({
				path: `/user/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerUpdate
		 * @request PATCH:/user/{id}
		 * @secure
		 */
		usersControllerUpdate: (id: string, data: UpdateUserDto, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/user/${id}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.FormData,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerRemove
		 * @request DELETE:/user/{id}
		 * @secure
		 */
		usersControllerRemove: (id: string, params: RequestParams = {}) =>
			this.request<Top5CoreValuesDto, any>({
				path: `/user/${id}`,
				method: "DELETE",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerGetCoreValues
		 * @request GET:/user/{id}/core-values
		 * @secure
		 */
		usersControllerGetCoreValues: (id: string, params: RequestParams = {}) =>
			this.request<CoreValue[], any>({
				path: `/user/${id}/core-values`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerGetMoments
		 * @request GET:/user/{id}/moments
		 * @secure
		 */
		usersControllerGetMoments: (id: string, params: RequestParams = {}) =>
			this.request<Moment[], any>({
				path: `/user/${id}/moments`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerGetScenes
		 * @request GET:/user/{id}/scenes
		 * @secure
		 */
		usersControllerGetScenes: (id: string, params: RequestParams = {}) =>
			this.request<Scene[], any>({
				path: `/user/${id}/scenes`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerUpdateMySettings
		 * @request PATCH:/user/me/settings
		 * @secure
		 */
		usersControllerUpdateMySettings: (data: UpdateUserSettingsDto, params: RequestParams = {}) =>
			this.request<UserSettings, any>({
				path: `/user/me/settings`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerUpdateSettings
		 * @request PATCH:/user/{id}/settings
		 * @secure
		 */
		usersControllerUpdateSettings: (
			id: string,
			data: UpdateUserSettingsDto,
			params: RequestParams = {},
		) =>
			this.request<UserSettings, any>({
				path: `/user/${id}/settings`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerGetAppData
		 * @request GET:/user/me/app-data
		 * @secure
		 */
		usersControllerGetAppData: (params: RequestParams = {}) =>
			this.request<Record<string, string | number | boolean>, any>({
				path: `/user/me/app-data`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags User
		 * @name UsersControllerUpdateAppData
		 * @request POST:/user/me/app-data
		 * @secure
		 */
		usersControllerUpdateAppData: (
			data: Record<string, string | number | boolean>,
			params: RequestParams = {},
		) =>
			this.request<Record<string, string | number | boolean>, any>({
				path: `/user/me/app-data`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	scenes = {
		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerCreate
		 * @request POST:/scenes
		 * @secure
		 */
		scenesControllerCreate: (data: CreateSceneDto, params: RequestParams = {}) =>
			this.request<Scene, any>({
				path: `/scenes`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerFindAll
		 * @request GET:/scenes
		 * @secure
		 */
		scenesControllerFindAll: (params: RequestParams = {}) =>
			this.request<Scene[], any>({
				path: `/scenes`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerFindOne
		 * @request GET:/scenes/{id}
		 * @secure
		 */
		scenesControllerFindOne: (id: string, params: RequestParams = {}) =>
			this.request<Scene, any>({
				path: `/scenes/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerRemove
		 * @request DELETE:/scenes/{id}
		 * @secure
		 */
		scenesControllerRemove: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/scenes/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerFindMessages
		 * @request GET:/scenes/{id}/messages
		 * @secure
		 */
		scenesControllerFindMessages: (id: string, params: RequestParams = {}) =>
			this.request<SceneMessage[], any>({
				path: `/scenes/${id}/messages`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerAnswerMessages
		 * @request PATCH:/scenes/{id}/messages/answer
		 * @secure
		 */
		scenesControllerAnswerMessages: (
			id: string,
			data: AnswerMessageDto,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/scenes/${id}/messages/answer`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Scenes
		 * @name ScenesControllerFinalize
		 * @request PATCH:/scenes/{id}/finalize
		 * @secure
		 */
		scenesControllerFinalize: (id: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/scenes/${id}/finalize`,
				method: "PATCH",
				secure: true,
				...params,
			}),
	};
	notification = {
		/**
		 * No description
		 *
		 * @tags Notification
		 * @name NotificationControllerSendMessage
		 * @request POST:/notification/send
		 * @secure
		 */
		notificationControllerSendMessage: (data: SendMessageDto, params: RequestParams = {}) =>
			this.request<boolean, any>({
				path: `/notification/send`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Notification
		 * @name NotificationControllerAddDevice
		 * @request POST:/notification/add-device
		 * @secure
		 */
		notificationControllerAddDevice: (data: AddDeviceDto, params: RequestParams = {}) =>
			this.request<UserDevice, any>({
				path: `/notification/add-device`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	corevalues = {
		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGenerateCoreValueAnalysisStream
		 * @request GET:/corevalues/generate/analysis
		 * @secure
		 */
		coreValuesControllerGenerateCoreValueAnalysisStream: (
			query: {
				type: "friendcheck" | "reflection";
			},
			params: RequestParams = {},
		) =>
			this.request<CoreValuesAnalysis[], any>({
				path: `/corevalues/generate/analysis`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGenerateCoreValueSummaryStream
		 * @request GET:/corevalues/generate/summary
		 * @secure
		 */
		coreValuesControllerGenerateCoreValueSummaryStream: (
			query: {
				type: "friendcheck" | "corevalues";
			},
			params: RequestParams = {},
		) =>
			this.request<string[], any>({
				path: `/corevalues/generate/summary`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGetFriendCheckCoreValueAnalysis
		 * @request GET:/corevalues/analysis
		 * @secure
		 */
		coreValuesControllerGetFriendCheckCoreValueAnalysis: (
			query: {
				type: "friendcheck" | "reflection";
			},
			params: RequestParams = {},
		) =>
			this.request<CoreValuesAnalysis[], any>({
				path: `/corevalues/analysis`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerDeleteAnalysis
		 * @request DELETE:/corevalues/analysis
		 * @secure
		 */
		coreValuesControllerDeleteAnalysis: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/corevalues/analysis`,
				method: "DELETE",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGetReflectionSummary
		 * @request GET:/corevalues/summary
		 * @secure
		 */
		coreValuesControllerGetReflectionSummary: (
			query: {
				type: "friendcheck" | "corevalues";
			},
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/corevalues/summary`,
				method: "GET",
				query: query,
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerDeleteSummary
		 * @request DELETE:/corevalues/summary
		 * @secure
		 */
		coreValuesControllerDeleteSummary: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/corevalues/summary`,
				method: "DELETE",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerSaveTopCoreValues
		 * @request POST:/corevalues/save
		 * @secure
		 */
		coreValuesControllerSaveTopCoreValues: (data: Top5CoreValuesDto, params: RequestParams = {}) =>
			this.request<string[], any>({
				path: `/corevalues/save`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGetAllCoreValues
		 * @request GET:/corevalues
		 * @secure
		 */
		coreValuesControllerGetAllCoreValues: (params: RequestParams = {}) =>
			this.request<CoreValueItem[], any>({
				path: `/corevalues`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerRefineCoreValue
		 * @request POST:/corevalues/chosen/refine
		 * @secure
		 */
		coreValuesControllerRefineCoreValue: (data: RefineCoreValueDto, params: RequestParams = {}) =>
			this.request<CoreValueItem[], any>({
				path: `/corevalues/chosen/refine`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGetChosenCoreValues
		 * @request GET:/corevalues/chosen
		 * @secure
		 */
		coreValuesControllerGetChosenCoreValues: (params: RequestParams = {}) =>
			this.request<ChosenCoreValueItem[], any>({
				path: `/corevalues/chosen`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Core Values
		 * @name CoreValuesControllerGetChosenCoreValue
		 * @request GET:/corevalues/chosen/{id}
		 * @secure
		 */
		coreValuesControllerGetChosenCoreValue: (id: string, params: RequestParams = {}) =>
			this.request<ChosenCoreValueItemDetail, any>({
				path: `/corevalues/chosen/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),
	};
	reflection = {
		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerGetReflection
		 * @request GET:/reflection
		 * @secure
		 */
		reflectionControllerGetReflection: (params: RequestParams = {}) =>
			this.request<ReflectionSummary[], any>({
				path: `/reflection`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerGenerate
		 * @request GET:/reflection/generate
		 * @secure
		 */
		reflectionControllerGenerate: (params: RequestParams = {}) =>
			this.request<ReflectionSummary[], any>({
				path: `/reflection/generate`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerGetReflectionById
		 * @request GET:/reflection/{id}
		 * @secure
		 */
		reflectionControllerGetReflectionById: (id: string, params: RequestParams = {}) =>
			this.request<ReflectionQuestionTree, any>({
				path: `/reflection/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerAnswerQuestion
		 * @request POST:/reflection/{id}
		 * @secure
		 */
		reflectionControllerAnswerQuestion: (
			id: string,
			data: PostReflectionAnswerRegular | PostReflectionAnswerChoice,
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/reflection/${id}`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerFinalizeQuestion
		 * @request POST:/reflection/finalize/{id}
		 * @secure
		 */
		reflectionControllerFinalizeQuestion: (id: string, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/reflection/finalize/${id}`,
				method: "POST",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerDeleteAnswer
		 * @request DELETE:/reflection/answers/{id}
		 * @secure
		 */
		reflectionControllerDeleteAnswer: (id: string, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/reflection/answers/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reflection
		 * @name ReflectionControllerDeleteAnswerForLatestVersion
		 * @request DELETE:/reflection/answers
		 * @secure
		 */
		reflectionControllerDeleteAnswerForLatestVersion: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/reflection/answers`,
				method: "DELETE",
				secure: true,
				...params,
			}),
	};
	friendcheck = {
		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerGetFriendHash
		 * @request GET:/friendcheck/hash
		 * @secure
		 */
		friendcheckControllerGetFriendHash: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/friendcheck/hash`,
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerGetAllSubmissions
		 * @request GET:/friendcheck/submissions
		 * @secure
		 */
		friendcheckControllerGetAllSubmissions: (params: RequestParams = {}) =>
			this.request<FriendCheckSubmission[], any>({
				path: `/friendcheck/submissions`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerDeleteAnswerForLatestVersion
		 * @request DELETE:/friendcheck/submissions
		 * @secure
		 */
		friendcheckControllerDeleteAnswerForLatestVersion: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/friendcheck/submissions`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerGetSubmission
		 * @request GET:/friendcheck/submissions/{id}
		 * @secure
		 */
		friendcheckControllerGetSubmission: (id: string, params: RequestParams = {}) =>
			this.request<FriendCheckSubmission, any>({
				path: `/friendcheck/submissions/${id}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerDeleteSubmission
		 * @request DELETE:/friendcheck/submissions/{id}
		 * @secure
		 */
		friendcheckControllerDeleteSubmission: (id: string, params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/friendcheck/submissions/${id}`,
				method: "DELETE",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerGetMyFriendCheck
		 * @request GET:/friendcheck/my-friendcheck
		 * @secure
		 */
		friendcheckControllerGetMyFriendCheck: (params: RequestParams = {}) =>
			this.request<FriendCheck, any>({
				path: `/friendcheck/my-friendcheck`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerGetPublicFriendCheck
		 * @request GET:/friendcheck/public/{friendId}/{friendHash}
		 * @secure
		 */
		friendcheckControllerGetPublicFriendCheck: (
			friendId: string,
			friendHash: string,
			params: RequestParams = {},
		) =>
			this.request<FriendCheck, any>({
				path: `/friendcheck/public/${friendId}/${friendHash}`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerPostPublicFriendCheck
		 * @request POST:/friendcheck/public/{friendId}/{friendHash}
		 * @secure
		 */
		friendcheckControllerPostPublicFriendCheck: (
			friendId: string,
			friendHash: string,
			data: SubmitFriendcheckDto,
			params: RequestParams = {},
		) =>
			this.request<FriendCheckSubmissionResponse, any>({
				path: `/friendcheck/public/${friendId}/${friendHash}`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerPatchPublicFriendCheckWithEmail
		 * @request PATCH:/friendcheck/public/{friendId}/{friendHash}
		 * @secure
		 */
		friendcheckControllerPatchPublicFriendCheckWithEmail: (
			friendId: string,
			friendHash: string,
			data: PatchFriendCheckEmailDto,
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/friendcheck/public/${friendId}/${friendHash}`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerGetFriendCheckEnabled
		 * @request GET:/friendcheck/enabled
		 * @secure
		 */
		friendcheckControllerGetFriendCheckEnabled: (params: RequestParams = {}) =>
			this.request<boolean, any>({
				path: `/friendcheck/enabled`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerUpdateFriendCheckEnabled
		 * @request PATCH:/friendcheck/enabled
		 * @secure
		 */
		friendcheckControllerUpdateFriendCheckEnabled: (
			data: PatchFriendCheckEnabledDto,
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/friendcheck/enabled`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerMarkFriendCheckSeen
		 * @request PATCH:/friendcheck/seen
		 * @secure
		 */
		friendcheckControllerMarkFriendCheckSeen: (
			data: PatchFriendCheckSeenDto,
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/friendcheck/seen`,
				method: "PATCH",
				body: data,
				secure: true,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Friendcheck
		 * @name FriendcheckControllerDeleteAnalysis
		 * @request DELETE:/friendcheck/analysis
		 * @secure
		 */
		friendcheckControllerDeleteAnalysis: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/friendcheck/analysis`,
				method: "DELETE",
				secure: true,
				...params,
			}),
	};
}
