import { Share as CapacitorShare } from "@capacitor/share";

export const Share = (function () {
	async function canShare() {
		return (await CapacitorShare.canShare()).value;
	}

	async function share(options: Parameters<typeof CapacitorShare.share>[0]) {
		return (await CapacitorShare.share(options)).activityType;
	}

	return {
		...CapacitorShare,
		canShare,
		share,
	};
})();

export type * from "@capacitor/share";
