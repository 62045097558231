import { IonButtons, IonHeader, IonPage, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { CoreValueAvatarWithBackground } from "@/components/CoreValue/AvatarWithBackground";
import { CoreValueRankingRow } from "@/components/CoreValue/RankingRow";
import { FriendCheckProgress } from "@/components/FriendCheck/Progress";
import { Button } from "@/components/Global/Button";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { ReflectionProgress } from "@/components/Reflection/Progress";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";
import { query_chosen } from "@/lib/query/functions/core-values/chosen";
import { query_coreValuesFriendCheck } from "@/lib/query/functions/core-values/friend-check";
import { query_coreValuesReflection } from "@/lib/query/functions/core-values/reflection";
import { useMyData } from "@/lib/query/functions/user/data";
import { useMyProgression } from "@/lib/query/functions/user/progression";
import { useSelectorModalStore } from "@/lib/store/modal";

export function CoreValues() {
	const { t } = useTranslation();
	const [myProgression] = useMyProgression();
	const presentModal = useSelectorModalStore.use.present();
	const [myData] = useMyData();

	const queryCoreValuesReflection = useQuery({
		...query_coreValuesReflection,
		enabled: myProgression?.reflection === "ANALYSED",
	});

	const queryCoreValuesFriendCheck = useQuery({
		...query_coreValuesFriendCheck,
		enabled:
			myProgression.friend_check === "ANALYSED" || myProgression.friend_check === "SUMMARIZED",
	});

	const coreValues = [
		...(queryCoreValuesReflection.data?.map((coreValue) => ({
			type: "reflection" as const,
			...coreValue,
		})) ?? []),
		...(queryCoreValuesFriendCheck.data
			?.filter((coreValue) => myData.coreValues.savedFriendCheck.includes(coreValue.id))
			.map((coreValue) => ({
				type: "friend-check" as const,
				...coreValue,
			})) ?? []),
	].sort((a, b) => (a.score > b.score ? -1 : 1));

	const queryCoreValuesChosen = useQuery({
		...query_chosen,
		enabled: myProgression.core_values === "SUMMARIZED",
	});

	const querySummaryCoreValues = useQuery({
		queryKey: ["core-values", "summary"],
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetReflectionSummary(
					{ type: "corevalues" },
					{ format: "text", signal: context.signal },
				),
			),
		enabled: myProgression.core_values === "SUMMARIZED",
	});

	const querySummaryFriendCheck = useQuery({
		queryKey: ["friend-check", "summary"],
		queryFn: (context) =>
			extractionWrapper(
				backendService.corevalues.coreValuesControllerGetReflectionSummary(
					{ type: "friendcheck" },
					{ format: "text", signal: context.signal },
				),
			),
		enabled: myProgression.core_values === "SUMMARIZED",
	});

	return (
		<IonPage>
			<IonHeader>
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonTitle className="text-lg font-bold ion-text-brown-700">
						{t("core-values.name")}
					</IonTitle>

					<IonButtons collapse slot="primary">
						<div className="size-10" />
					</IonButtons>
				</Toolbar>
			</IonHeader>
			<Content className="part-scroll:gap-8">
				<IonHeader collapse="condense" className="-mt-[--padding-top]">
					<IonToolbar className="ion-px-0">
						<IonTitle className="p-0 text-start text-3xl font-bold ion-text-brown-700">
							{t("core-values.name")}
						</IonTitle>

						<IonButtons collapse slot="primary">
							<div className="size-10" />
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<CoreValueAvatarWithBackground
					className="py-8"
					coreValues={queryCoreValuesChosen.data ?? coreValues}
				/>

				{myProgression.core_values !== "SUMMARIZED" && (
					<div className="space flex flex-col divide-y divide-brown-200 rounded-2xl border border-brown-200 drop-shadow-[0px_4px_24px_0px_theme(colors.black/.25)] contain-paint *:rounded-none *:border-x-0">
						<ReflectionProgress />
						<FriendCheckProgress />
					</div>
				)}

				{myProgression.reflection === "ANALYSED" &&
					(myProgression.friend_check === "ANALYSED" ||
						myProgression.friend_check === "SUMMARIZED") &&
					myProgression.core_values === "NOT_STARTED" && (
						<Button
							className="text-lg font-bold !ion-rounded-2xl"
							onClick={() => presentModal("coreValuesCompileGeneral")}
						>
							{t("core-values.compile-top-5")}
						</Button>
					)}

				{myProgression.core_values === "SUMMARIZED" ? (
					<div className="flex flex-col gap-4">
						<ol className="flex flex-col gap-3">
							{queryCoreValuesChosen.data?.map((coreValue, index) => (
								<CoreValueRankingRow
									key={index}
									data={{ ...coreValue.coreValue, number: index + 1, type: "compiled" }}
									transition={{ duration: 0.2, delay: 0.19 * index }}
									className="cursor-pointer"
									onClick={() =>
										presentModal("coreValuesDetail", {
											item: {
												name: coreValue.customName ?? coreValue.coreValue.name,
												reason: coreValue.reason,
											},
										})
									}
								/>
							))}
						</ol>

						<motion.div
							className="flex flex-col gap-2 rounded-xl border border-brown-200 bg-brown-100 p-4"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ delay: 2 }}
						>
							<IonText className="text-base font-semibold text-brown-500">{t("about-you")}</IonText>
							<IonText className="text-pretty text-lg font-semibold text-brown-700">
								{querySummaryCoreValues.data}
							</IonText>
						</motion.div>

						<motion.div
							className="flex flex-col gap-2 rounded-xl border border-brown-200 bg-brown-100 p-4"
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ delay: 2.5 }}
						>
							<IonText className="text-base font-semibold text-brown-500">
								{t("what-friends-say-about-you")}
							</IonText>
							<IonText className="text-pretty text-lg font-semibold text-brown-700">
								{querySummaryFriendCheck.data}
							</IonText>
						</motion.div>
					</div>
				) : (
					(myProgression.reflection === "ANALYSED" ||
						myProgression.friend_check === "SUMMARIZED" ||
						myProgression.friend_check === "ANALYSED") && (
						<div className="flex flex-col gap-4">
							<div className="flex flex-col gap-1">
								<IonText className="text-xl font-bold text-brown-600">
									{t("core-values.page.provisional.title")}
								</IonText>
								<IonText className="text-sm text-brown-600">
									{t("core-values.page.provisional.text", {
										section:
											myProgression.friend_check === "SUMMARIZED" ||
											myProgression.friend_check === "ANALYSED"
												? t("friend-check.name")
												: t("reflection.name"),
									})}
								</IonText>
							</div>

							<ol className="flex flex-1 flex-col gap-3">
								{coreValues.map((coreValue, index) => (
									<CoreValueRankingRow
										key={index}
										data={{ ...coreValue, number: index + 1 }}
										transition={{ duration: 0.1, delay: index * 0.1 }}
										onClick={() => presentModal("coreValuesDetail", { item: coreValue })}
									/>
								))}
							</ol>
						</div>
					)
				)}
			</Content>
		</IonPage>
	);
}
