import { Toast as CapacitorToast, type ShowOptions } from "@capacitor/toast";

export const Toast = (function () {
	async function show(options: ShowOptions) {
		return await CapacitorToast.show(options);
	}

	return {
		...CapacitorToast,
		show,
	};
})();

export type * from "@capacitor/toast";
