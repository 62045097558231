import { IonChip, IonIcon, IonModal } from "@ionic/react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import Color from "color";
import { Button } from "@/components/Global/Button";
import { NumberTicker } from "@/components/magicui/number-ticker";
import { userGroupIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { query_coreValues } from "@/lib/query/functions/core-values/all";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalCoreValuesDetail() {
	const { t, i18n } = useTranslation();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.coreValuesDetail();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();

	useEffect(() => {
		if (modal.isOpen && !modal.item) {
			modalRef.current?.dismiss();
		}
	}, [modal.isOpen, modal.item]);

	const queryCoreValue = useQuery({
		...query_coreValues,
		select: (data) => data?.find((item) => item.name === modal.item?.name),
		enabled: modal.isOpen && !!modal.item,
	});

	const coreValue = {
		...modal.item,
		...queryCoreValue.data,
	};

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("coreValuesDetail")}
			onWillDismiss={() => dismiss("coreValuesDetail", { item: null })}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-6 pb-8 pt-12">
				{!!coreValue && (
					<div
						className="flex flex-col items-center gap-4 rounded-2xl bg-gradient-to-b from-[--from] to-[--to] px-4 py-6"
						style={{
							// @ts-expect-error CSS Variables are not typed
							"--from": Color(coreValue.color).alpha(0.25).rgb().toString(),
							"--to": Color(coreValue.color).alpha(0.4).rgb().toString(),
							"--background": Color(coreValue.color).alpha(0.25).rgb().toString(),
							"--foreground": Color(coreValue.color).darken(0.625).rgb().toString(),
						}}
					>
						<div
							className="relative isolate grid size-20 place-content-center rounded-full border-4 text-3xl text-[--foreground]"
							style={{ borderColor: coreValue.color }}
						>
							{coreValue.emoji}

							{coreValue.type === "friend-check" ? (
								<IonIcon
									icon={userGroupIcon}
									className="absolute -right-4 bottom-0 size-5 rounded-full p-1 text-start text-base font-bold"
									style={{ background: coreValue.color }}
								/>
							) : (
								<NumberTicker
									className="absolute -right-7 bottom-0 rounded-full p-1 text-start text-base font-bold"
									style={{ background: coreValue.color }}
									value={coreValue.score ?? 0}
									getTextContent={(value) =>
										Intl.NumberFormat(i18n.language, { style: "unit", unit: "percent" }).format(
											value.toFixed(0),
										)
									}
									initial="hidden"
									animate={coreValue.score ? "visible" : "hidden"}
									variants={{
										visible: { opacity: 1, pointerEvents: "auto" },
										hidden: { opacity: 0, pointerEvents: "none" },
									}}
									transition={{ duration: 0.25, delay: 0.25 }}
									checkInView={false}
									duration={0.25}
									delay={0.25}
								/>
							)}
						</div>
						<IonChip className="cursor-auto rounded-full bg-[--background] text-lg font-semibold text-[--foreground]">
							{coreValue?.name}
						</IonChip>
						<p data-ph-no-capture className="text-pretty text-black">
							{coreValue?.reason}
						</p>
					</div>
				)}

				<Button
					expand="block"
					fill="clear"
					className="text-lg font-bold ion-bg-a-brown-100 ion-bg-brown-100 ion-bg-f-brown-100 ion-bg-h-brown-100 ion-text-brown-700 !ion-rounded-2xl"
					onClick={() => modalRef.current?.dismiss()}
				>
					{t("close")}
				</Button>
			</div>
		</IonModal>
	);
}
