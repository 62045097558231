import { useEffect, useState } from "react";
import { Keyboard } from "@/lib/capacitor/Keyboard";

export function useKeyboard() {
	const [isOpen, setIsOpen] = useState(false);
	const [keyboardHeight, setKeyboardHeight] = useState(0);

	useEffect(() => {
		Keyboard.addWillShowListener(() => {
			setIsOpen(true);
		});
		Keyboard.addWillHideListener(() => {
			setIsOpen(false);
		});
		Keyboard.addDidShowListener((ki) => {
			const keyboardHeight = ki.keyboardHeight;
			setIsOpen(true);
			setKeyboardHeight(keyboardHeight);
		});
		Keyboard.addDidHideListener(() => {
			setIsOpen(false);
			setKeyboardHeight(0);
		});

		return () => {
			Keyboard.removeAllListeners();
		};
	}, [setIsOpen, setKeyboardHeight]);

	return {
		isOpen,
		keyboardHeight,
	};
}
