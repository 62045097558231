import { Haptics } from "@/lib/capacitor/Haptics";
import { useSelectorPreferencesStore } from "@/lib/store/preferences";

export function usePreferredHaptics() {
	const reducedHaptics = useSelectorPreferencesStore.use.reducedHaptics();

	async function impact(...args: Parameters<typeof Haptics.impact>) {
		if (reducedHaptics) {
			return;
		}

		return await Haptics.impact(...args);
	}

	async function notification(...args: Parameters<typeof Haptics.notification>) {
		if (reducedHaptics) {
			return;
		}

		return await Haptics.notification(...args);
	}

	async function vibrate(...args: Parameters<typeof Haptics.vibrate>) {
		if (reducedHaptics) {
			return;
		}

		return await Haptics.vibrate(...args);
	}

	async function selectionStart() {
		if (reducedHaptics) {
			return;
		}

		return await Haptics.selectionStart();
	}

	async function selectionChanged() {
		if (reducedHaptics) {
			return;
		}

		return await Haptics.selectionChanged();
	}

	async function selectionEnd() {
		if (reducedHaptics) {
			return;
		}

		return await Haptics.selectionEnd();
	}

	return {
		impact,
		notification,
		vibrate,
		selectionStart,
		selectionChanged,
		selectionEnd,
	};
}
