import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { ReflectionChatBubble } from "@/components/Reflection/Chat/Bubble";
import { type ReflectionQuestionTree } from "@/lib/backend/api";

export function ReflectionChatFollowUpQuestion({
	followUpQuestion,
}: {
	followUpQuestion: ReflectionQuestionTree | null;
}) {
	const { i18n } = useTranslation();

	if (!followUpQuestion) {
		return null;
	}

	return (
		<>
			<ReflectionChatBubble key={followUpQuestion.id} sender="bot">
				{followUpQuestion.answers.length > 0
					? followUpQuestion.question
					: followUpQuestion.question.split(" ").map((word, index) => (
							<motion.span
								key={word + index}
								initial={{ opacity: 0, display: "none" }}
								animate={{ opacity: 1, display: "inline" }}
								transition={{ duration: 0.1, delay: 0.1 * index }}
							>
								{word}{" "}
							</motion.span>
						))}
			</ReflectionChatBubble>

			{followUpQuestion.answers.length > 0 && (
				<>
					{followUpQuestion.answers.map((answer) => {
						switch (answer.questionType) {
							case "MULTIPLE_CHOICE":
								return (
									<ReflectionChatBubble key={answer.id} sender="user">
										{new Intl.ListFormat(i18n.language).format(answer.options)}
									</ReflectionChatBubble>
								);
							case "NUMBER":
							case "DATA":
							case "EMAIL":
							case "TEXT":
							default:
								return (
									<ReflectionChatBubble key={answer.id} sender="user">
										{answer.content}
									</ReflectionChatBubble>
								);
						}
					})}

					{!!followUpQuestion.followUpQuestion && (
						<ReflectionChatFollowUpQuestion
							key={followUpQuestion.followUpQuestion.id}
							followUpQuestion={followUpQuestion.followUpQuestion}
						/>
					)}
				</>
			)}
		</>
	);
}
