import { IonSpinner } from "@ionic/react";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/Global/Button";
import { cx } from "@/lib/style/cva.config";

export const AuthSubmitButton = forwardRef<
	HTMLIonButtonElement,
	React.ComponentProps<typeof Button> & { isSubmitting?: boolean }
>(function AuthSubmitButton({ isSubmitting = false, ...props }, ref) {
	const { t } = useTranslation();

	return (
		<Button
			ref={ref}
			type="submit"
			expand="block"
			{...props}
			className={cx(
				"m-0 h-12 ion-rounded-2xl part-native:text-lg part-native:font-bold",
				props.className,
			)}
		>
			{isSubmitting ? (
				<>
					<IonSpinner slot="start" className="me-2" />
					{`${t("loading")}...`}
				</>
			) : (
				props.children
			)}
		</Button>
	);
});
