// sort-imports-ignore
import "@/utils/polyfills/cryptoRandomUUID";
import "@/utils/polyfills/formRequestSubmit";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { App } from "@/App";
import { Providers } from "@/components/Providers";
import "@/i18n";
import "@/lib/sentry";
import "@/styles/index.css";

defineCustomElements(window);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	<StrictMode>
		<Providers>
			<App />
		</Providers>
	</StrictMode>,
);
