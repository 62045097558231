import { useMemo } from "react";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryKey_coreValues = ["core-values", "all"] as const;
export const queryFn_coreValues = (context: QueryFunctionContext<typeof queryKey_coreValues>) =>
	extractionWrapper(
		backendService.corevalues.coreValuesControllerGetAllCoreValues({ signal: context.signal }),
	);

type QueryOptions = UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_coreValues>>,
	Error,
	Awaited<ReturnType<typeof queryFn_coreValues>>,
	typeof queryKey_coreValues
>;

export const query_coreValues = {
	queryFn: queryFn_coreValues,
	queryKey: queryKey_coreValues,
	staleTime: Infinity,
	retry: false,
	retryOnMount: false,
	refetchOnReconnect: false,
	refetchOnWindowFocus: false,
	refetchOnMount: false,
} satisfies QueryOptions;

export function useCoreValues(options?: Partial<QueryOptions>) {
	const isAuthenticated = useAuthStore(authSelectors.isAuthenticated);

	const query = useQuery({
		...query_coreValues,
		enabled: isAuthenticated,
		...options,
	});
	const coreValues = query.data;

	return useMemo(
		() => [coreValues, query] as const,
		[coreValues, query.status, query.dataUpdatedAt, query.data],
	);
}
