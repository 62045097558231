import { IonSpinner, IonText } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { cx } from "@/lib/style/cva.config";

export function Loading(props: React.ComponentPropsWithoutRef<"div">) {
	const { t } = useTranslation();

	return (
		<div
			{...props}
			className={cx("flex flex-col items-center justify-center gap-2", props.className)}
		>
			<IonSpinner />
			<IonText className="text-xs">{t("loading")}...</IonText>
		</div>
	);
}
