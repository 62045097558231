import { IonButtons, IonIcon, IonPage, IonRouterLink } from "@ionic/react";
import { Trans, useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthStrategyAlert } from "@/components/Auth/Strategy/Alert";
import { AuthStrategyOAuth } from "@/components/Auth/Strategy/OAuth";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { Button } from "@/components/Global/Button";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import { type SignInDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { envelopeIcon, keyIcon, sparklesIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { logger } from "@/lib/logger";
import { Routes } from "@/lib/router";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function AuthSignInPassword() {
	const posthog = usePostHog();
	const state = useAuthFlowStore();
	const authenticate = useSelectorAuthStore.use.authenticate();
	const { t } = useTranslation();

	const mutationSignIn = useExtractedMutation({
		mutationKey: ["auth", "sign-in"],
		fn: (variables: SignInDto) => backendService.auth.authControllerSignin(variables),
	});

	const form = useZodForm({
		defaultValues: {
			email: state.email,
			password: state.password,
		},
		onSubmit: ({ value, formApi }) =>
			mutationSignIn.mutateAsync(value, {
				onSuccess: (data) => {
					if (!data) {
						throw new Error("Sign In Failed", { cause: "No data" });
					}

					posthog.capture("User Sign In", { method: "password", success: true });
					authenticate(data);
					useAuthFlowStore.getState().reset();
				},
				onError: (error) => {
					posthog.capture("User Sign In", { method: "password", success: false });

					logger.error({ error }, "Sign In Failed");

					formApi.setFieldMeta("password", (current) => ({
						...current,
						errorMap: {
							onSubmit: t("fields.password.errors.invalid"),
						},
					}));
				},
			}),
	});

	return (
		<IonPage>
			<AuthHeader>
				<IonButtons slot="primary">
					<Button
						routerLink={Routes.AuthSignUp()}
						routerDirection="forward"
						color="tertiary"
						size="small"
						className="touch-target part-native:text-brown-600"
					>
						<span>
							<Trans i18nKey="page.auth.sign-in.header.primary-button" />
						</span>
					</Button>
				</IonButtons>
			</AuthHeader>
			<AuthContent>
				<TitleIcon icon={sparklesIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.sign-in.title") },
						subtitle: { children: t("page.auth.sign-in.subtitle") },
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						validators={{
							onSubmit: z.string().email(t("fields.email.errors.invalid")),
						}}
						children={(field) => (
							<FormInput
								field={field}
								type="email"
								label={t("fields.email.label")}
								autocomplete="email"
								inputMode="email"
								placeholder={t("fields.email.placeholder")}
							>
								<IonIcon slot="start" icon={envelopeIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Field
						name="password"
						validators={{ onChange: z.string() }}
						children={(field) => (
							<FormInput
								field={field}
								type="password"
								label={t("fields.password.label")}
								autocomplete="current-password"
								placeholder={t("fields.password.placeholder")}
							>
								<IonIcon slot="start" icon={keyIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<div className="flex flex-col gap-4">
						<form.Subscribe
							selector={(state) => [state.canSubmit, state.isSubmitting]}
							children={([canSubmit, isSubmitting]) => (
								<AuthSubmitButton disabled={!canSubmit} isSubmitting={isSubmitting}>
									{t("page.auth.sign-in.steps.password.action.primary")}
								</AuthSubmitButton>
							)}
						/>

						<Button
							fill="clear"
							color="secondary"
							routerLink={Routes.AuthForgotPassword()}
							routerDirection="forward"
							className="touch-target min-h-0 text-sm font-semibold ion-text-brown-600 ion-py-2.5"
						>
							{t("page.auth.sign-in.steps.password.action.secondary")}
						</Button>

						<AuthStrategyAlert>
							<Trans
								i18nKey="page.auth.sign-in.steps.password.alert"
								components={[
									<IonRouterLink
										key="0"
										routerLink={Routes.AuthSignInMagic()}
										routerDirection="back"
										className="inline font-bold hover:underline focus:underline active:underline"
									/>,
								]}
							/>
						</AuthStrategyAlert>
					</div>
				</form>

				<AuthStrategyOAuth />
			</AuthContent>
		</IonPage>
	);
}
