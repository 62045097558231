import { IonToolbar } from "@ionic/react";
import { forwardRef } from "react";
import { cx } from "@/lib/style/cva.config";

export const Toolbar = forwardRef<HTMLIonToolbarElement, React.ComponentProps<typeof IonToolbar>>(
	function Toolbar(props, ref) {
		return (
			<IonToolbar
				ref={ref}
				{...props}
				className={cx(
					"transition-none ion-bg-transparent ion-border-brown-300 ion-px-4 ion-py-2",
					props.className,
				)}
			>
				{props.children}
			</IonToolbar>
		);
	},
);
