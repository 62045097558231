import { type FormOptions, type Validator, useForm } from "@tanstack/react-form";
import { zodValidator } from "@tanstack/zod-form-adapter";

export function useZodForm<TFormData>(
	opts?: Omit<
		FormOptions<TFormData, Validator<TFormData, unknown> | undefined>,
		"validatorAdapter"
	>,
) {
	return useForm({
		validatorAdapter: zodValidator(),
		...opts,
	});
}
