import { Api, type ApiConfig } from "@/lib/backend/api";
import { fetch } from "@/lib/backend/fetch";
import { env } from "@/lib/env";

export type SecurityData = { accessToken?: string };

export const backendService = createApi({
	securityWorker: (securityData) => ({
		headers: {
			...(securityData?.accessToken && {
				Authorization: `Bearer ${securityData.accessToken}`,
			}),
		},
	}),
	customFetch: fetch,
});

export function createApi<T extends SecurityData>(apiConfig?: Omit<ApiConfig<T>, "baseUrl">) {
	return new Api<T>({
		baseUrl: env.VITE_BACKEND_URL,
		...apiConfig,
	});
}
