import { IonIcon, IonText } from "@ionic/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { Button } from "@/components/Global/Button";
import { hasAnswer } from "@/lib/functions/answer";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/16/solid";
import { query_reflection } from "@/lib/query/functions/reflection/all";
import { useMyData } from "@/lib/query/functions/user/data";
import { Routes } from "@/lib/router";
import { cx } from "@/lib/style/cva.config";
import { DashboardReflectionWidgetStack as Stack } from "./parts/Stack";

export function DashboardReflectionWidget(props: React.ComponentPropsWithoutRef<"div">) {
	const { t } = useTranslation();

	const [myData] = useMyData();
	const query = useQuery(query_reflection);

	const sortedData = useMemo(
		() =>
			[...(query.data ?? [])].sort((a, b) => {
				const completed =
					Number(!!b.firstAnswer && b.firstAnswer.length > 0) -
					Number(!!a.firstAnswer && a.firstAnswer.length > 0);

				if (completed === 0) {
					return (
						Number(hasAnswer(myData.reflection.questions[b.id])) -
						Number(hasAnswer(myData.reflection.questions[a.id]))
					);
				}

				return completed;
			}),
		[query.data, myData.reflection.questions],
	);

	if (!query.isLoading && query.data?.every((question) => question.isCompleted)) {
		return null;
	}

	return (
		<div {...props} className={cx("flex flex-col gap-1 py-2", props.className)}>
			<div className="flex flex-row items-center justify-between gap-4">
				<IonText className="text-xl font-bold text-brown-600">
					{t("reflection.widget.title")}
				</IonText>

				<Button
					fill="clear"
					className="touch-target -mx-4 w-fit text-sm font-semibold text-brown-600"
					routerLink={Routes.Reflection()}
					routerDirection="root"
				>
					{t("reflection.widget.action")}
					<IonIcon slot="end" icon={arrowRightIcon} className="size-4 text-brown-400" />
				</Button>
			</div>

			<Stack isLoading={query.isLoading} data={sortedData} />
		</div>
	);
}
