import { IonItem } from "@ionic/react";
import { cx } from "@/lib/style/cva.config";

export function SectionItem(props: React.ComponentProps<typeof IonItem>) {
	return (
		<IonItem
			{...props}
			className={cx(
				"text-brown-700 ion-bg-white ion-border-brown-200 ion-detail-icon-brown-400 ion-opacity-100 ion-inner-px-3 ion-inner-py-1 ion-px-2",
				props.className,
			)}
		>
			{props.children}
		</IonItem>
	);
}
