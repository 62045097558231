/**
 * Polyfill for HTMLFormElement.prototype.requestSubmit
 *
 * @lends https://github.com/javan/form-request-submit-polyfill/blob/main/form-request-submit-polyfill.js
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/requestSubmit
 */

if (
	typeof window !== "undefined" &&
	typeof HTMLFormElement.prototype.requestSubmit !== "function"
) {
	HTMLFormElement.prototype.requestSubmit = function (submitter) {
		if (!submitter) {
			const element = document.createElement("input");
			element.type = "submit";
			element.hidden = true;
			this.appendChild(element);
			element.click();
			this.removeChild(element);
			return;
		}

		if (!(submitter instanceof HTMLElement)) {
			throw new TypeError(
				"Failed to execute 'requestSubmit' on 'HTMLFormElement': The specified element is not of type 'HTMLElement'.",
			);
		}

		if (!(submitter instanceof HTMLInputElement)) {
			throw new TypeError(
				"Failed to execute 'requestSubmit' on 'HTMLFormElement': The specified element is not of type 'HTMLInputElement'.",
			);
		}

		if (submitter.type != "submit") {
			throw new TypeError(
				"Failed to execute 'requestSubmit' on 'HTMLFormElement': The specified element is not of type of submit.",
			);
		}

		if (submitter.form != this) {
			throw new DOMException(
				"Failed to execute 'requestSubmit' on 'HTMLFormElement': The specified element is not owned by this form element.",
				"NotFoundError",
			);
		}

		submitter.click();
	};
}
