import {
	type DefaultError,
	type QueryFunctionContext,
	type UseQueryOptions,
} from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";

export const queryKey_chosen = ["core-values", "chosen"] as const;
export const queryFn_chosen = (context: QueryFunctionContext<typeof queryKey_chosen>) =>
	extractionWrapper(
		backendService.corevalues.coreValuesControllerGetChosenCoreValues({
			signal: context.signal,
		}),
	);

export const query_chosen = {
	queryKey: queryKey_chosen,
	queryFn: queryFn_chosen,
} satisfies UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_chosen>>,
	DefaultError,
	Awaited<ReturnType<typeof queryFn_chosen>>,
	typeof queryKey_chosen
>;
