import { type z } from "zod";
import { jsonAutocomplete } from "@/utils/json/autocomplete";
import { jsonClean } from "@/utils/json/clean";

export function jsonParse<T extends z.ZodTypeAny>({
	input,
	schema,
}: {
	input: string;
	schema: T;
}): z.infer<T> | undefined {
	const cleaned = jsonClean(input);
	const completed = jsonAutocomplete(cleaned);

	if (!completed) {
		return;
	}

	try {
		return schema.parse(JSON.parse(completed));
	} catch (error) {
		console.error({ error, input, cleaned, completed });
	}
}
