import { Clipboard as CapacitorClipboard } from "@capacitor/clipboard";

export const Clipboard = (function () {
	async function write(options: Parameters<typeof CapacitorClipboard.write>[0]) {
		return await CapacitorClipboard.write(options);
	}

	async function read() {
		return await CapacitorClipboard.read();
	}

	return {
		...CapacitorClipboard,
		write,
		read,
	};
})();

export type * from "@capacitor/clipboard";
