import { type ValidationError } from "@tanstack/react-form";
import { type ArrayOrSingle } from "ts-essentials";
import { getArrayOrSingle } from "@/utils/functions/getArrayOrSingle";

export function getErrorText({
	language,
	errors = [],
}: {
	language: string;
	errors: ArrayOrSingle<ValidationError>;
}): string {
	const formatter = new Intl.ListFormat(language, { type: "conjunction" });
	const messages = getArrayOrSingle(errors).filter((error): error is string => !!error);

	if (messages.length === 0) {
		return "";
	}

	return formatter.format(messages);
}
