import {
	type ActionPerformed,
	type CancelOptions,
	LocalNotifications as CapacitorLocalNotifications,
	type Channel,
	type DeliveredNotifications,
	type LocalNotificationSchema,
	type RegisterActionTypesOptions,
	type ScheduleOptions,
} from "@capacitor/local-notifications";
import { type ArrayOrSingle } from "ts-essentials";
import { Capacitor } from "@/lib/capacitor";
import { logger } from "@/lib/logger";
import { getArrayOrSingle } from "@/utils/functions/getArrayOrSingle";

export const LocalNotifications = (function () {
	async function schedule(notifications: ArrayOrSingle<ScheduleOptions["notifications"][number]>) {
		return (
			await CapacitorLocalNotifications.schedule({ notifications: getArrayOrSingle(notifications) })
		).notifications;
	}

	async function getPending() {
		return (await CapacitorLocalNotifications.getPending()).notifications;
	}

	async function registerActionTypes(types: RegisterActionTypesOptions["types"]) {
		if (Capacitor.Platform === "web") {
			logger.debug("LocalNotifications.registerActionTypes() is not implemented on web");
			return;
		}

		return await CapacitorLocalNotifications.registerActionTypes({ types });
	}

	async function cancel(notifications: ArrayOrSingle<CancelOptions["notifications"][number]>) {
		return await CapacitorLocalNotifications.cancel({
			notifications: getArrayOrSingle(notifications),
		});
	}

	async function getDeliveredNotifications() {
		return (await CapacitorLocalNotifications.getDeliveredNotifications()).notifications;
	}

	async function removeDeliveredNotifications(
		notifications: DeliveredNotifications["notifications"],
	) {
		return await CapacitorLocalNotifications.removeDeliveredNotifications({ notifications });
	}

	async function removeAllDeliveredNotifications() {
		return await CapacitorLocalNotifications.removeAllDeliveredNotifications();
	}

	async function createChannel(channel: Channel) {
		if (Capacitor.Platform !== "android") {
			logger.debug("LocalNotifications.createChannel() is only available on Android");
			return;
		}

		return await CapacitorLocalNotifications.createChannel(channel);
	}

	async function deleteChannel(id: Channel["id"]) {
		if (Capacitor.Platform !== "android") {
			logger.debug("LocalNotifications.createChannel() is only available on Android");
			return;
		}

		return await CapacitorLocalNotifications.deleteChannel({ id });
	}

	async function listChannels() {
		if (Capacitor.Platform !== "android") {
			logger.debug("LocalNotifications.createChannel() is only available on Android");
			return;
		}

		return (await CapacitorLocalNotifications.listChannels()).channels;
	}

	async function checkPermissions() {
		return (await CapacitorLocalNotifications.checkPermissions()).display;
	}

	async function requestPermissions() {
		return (await CapacitorLocalNotifications.requestPermissions()).display;
	}

	function addLocalNotificationReceivedListener(
		listenerFunc: (notification: LocalNotificationSchema) => void,
	) {
		return CapacitorLocalNotifications.addListener("localNotificationReceived", listenerFunc);
	}

	function addLocalNotificationActionPerformedListener(
		listenerFunc: (notification: ActionPerformed) => void,
	) {
		return CapacitorLocalNotifications.addListener(
			"localNotificationActionPerformed",
			listenerFunc,
		);
	}

	function removeAllListeners() {
		return CapacitorLocalNotifications.removeAllListeners();
	}

	return {
		...CapacitorLocalNotifications,
		schedule,
		getPending,
		registerActionTypes,
		cancel,
		getDeliveredNotifications,
		removeDeliveredNotifications,
		removeAllDeliveredNotifications,
		createChannel,
		deleteChannel,
		listChannels,
		checkPermissions,
		requestPermissions,
		addLocalNotificationReceivedListener,
		addLocalNotificationActionPerformedListener,
		removeAllListeners,
	};
})();

export type * from "@capacitor/local-notifications";
