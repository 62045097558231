import { IonButtons, IonIcon, IonPage, IonRouterLink, useIonRouter } from "@ionic/react";
import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthStrategyAlert } from "@/components/Auth/Strategy/Alert";
import { AuthStrategyOAuth } from "@/components/Auth/Strategy/OAuth";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { getErrorText } from "@/components/Form/utils";
import { Button } from "@/components/Global/Button";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import { type EmailVerificationCodeDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { Toast } from "@/lib/capacitor/Toast";
import { envelopeIcon, sparklesIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { Routes } from "@/lib/router";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function AuthSignInMagic() {
	const posthog = usePostHog();
	const state = useAuthFlowStore();
	const router = useIonRouter();
	const params = Routes.AuthSignInMagic.useParams();
	const { t, i18n } = useTranslation();

	const mutationSendMagicLink = useExtractedMutation({
		mutationKey: ["auth", "magic", "send"],
		fn: (variables: EmailVerificationCodeDto) =>
			backendService.auth.authControllerSendMagicLink(variables, { format: "text" }),
	});

	useEffect(() => {
		if (params.error) {
			switch (params.error) {
				case "google_signin_cancelled":
					Toast.show({
						text: t("auth.error.google_cancelled"),
						duration: "long",
					});
					break;
				case "apple_signin_cancelled":
					Toast.show({
						text: t("auth.error.apple_cancelled"),
						duration: "long",
					});
					break;
				default:
					Toast.show({
						text: t("auth.error.other"),
						duration: "long",
					});
					break;
			}
		}
	}, [params.error]);

	const form = useZodForm({
		defaultValues: { email: state.email },
		onSubmit: ({ value, formApi }) =>
			mutationSendMagicLink.mutateAsync(value, {
				onSuccess: (_data, variables) => {
					posthog.capture("User Send Magic Link");
					useAuthFlowStore.setState(variables);
					router.push(Routes.AuthSignInMagicVerify(), "forward");
				},
				onError: (error) => {
					let message: string;

					if (error instanceof Error) {
						message = error.message;
					} else {
						if (error.message.length === 1 && error.message.includes("email must be an email")) {
							message = t("fields.email.errors.invalid");
						} else {
							message = getErrorText({ language: i18n.language, errors: error.message });
						}
					}

					formApi.setFieldMeta("email", (current) => ({
						...current,
						errorMap: {
							onSubmit: message,
						},
					}));
				},
			}),
	});

	return (
		<IonPage>
			<AuthHeader>
				<IonButtons slot="primary">
					<Button
						routerLink={Routes.AuthSignUp()}
						routerDirection="forward"
						color="tertiary"
						size="small"
						className="touch-target part-native:text-brown-600"
					>
						<span>
							<Trans i18nKey="page.auth.sign-in.header.primary-button" />
						</span>
					</Button>
				</IonButtons>
			</AuthHeader>
			<AuthContent className="ion-pt-0">
				<TitleIcon icon={sparklesIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.sign-in.title") },
						subtitle: { children: t("page.auth.sign-in.subtitle") },
					}}
				/>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						validators={{
							onSubmit: z.string().email(t("fields.email.errors.invalid")),
						}}
						children={(field) => (
							<FormInput
								field={field}
								type="email"
								label={t("fields.email.label")}
								autocomplete="email"
								inputMode="email"
								placeholder={t("fields.email.placeholder")}
							>
								<IonIcon slot="start" icon={envelopeIcon} aria-hidden="true" />
							</FormInput>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton disabled={!canSubmit} isSubmitting={isSubmitting}>
								{t("page.auth.sign-in.steps.magic.action.primary")}
							</AuthSubmitButton>
						)}
					/>

					<AuthStrategyAlert>
						<Trans
							i18nKey="page.auth.sign-in.steps.magic.alert"
							components={[
								<IonRouterLink
									key="0"
									routerLink={Routes.AuthSignInPassword()}
									routerDirection="forward"
									className="inline font-bold hover:underline focus:underline active:underline"
								/>,
							]}
						/>
					</AuthStrategyAlert>
				</form>

				<AuthStrategyOAuth />
			</AuthContent>
		</IonPage>
	);
}
