import { useEffect } from "react";
import { PostHogProvider as Provider } from "posthog-js/react";
import { getFullName } from "@/lib/getFullName";
import { posthog } from "@/lib/posthog";
import { useMe } from "@/lib/query/functions/user/me";

export function PostHogProvider(props: React.ComponentProps<typeof Provider>) {
	const [me] = useMe();

	useEffect(() => {
		if (!me) {
			return;
		}

		posthog.identify(me.id, {
			email: me.email,
			emailVerified: me.emailVerified,
			name: getFullName(me),
			image: me.avatarURL,
			authSource: me.authSource,
		});
	}, [me]);

	return <Provider client={posthog} {...props} />;
}
