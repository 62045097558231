import {
	type DefaultError,
	type QueryFunctionContext,
	type UseQueryOptions,
} from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";

export const queryKey_reflection = ["reflection", "all"] as const;
export const queryFn_reflection = (context: QueryFunctionContext<typeof queryKey_reflection>) =>
	extractionWrapper(
		backendService.reflection.reflectionControllerGetReflection({
			signal: context.signal,
		}),
	);

export const query_reflection = {
	queryKey: queryKey_reflection,
	queryFn: queryFn_reflection,
} satisfies UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_reflection>>,
	DefaultError,
	Awaited<ReturnType<typeof queryFn_reflection>>,
	typeof queryKey_reflection
>;
