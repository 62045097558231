import {
	IonBackButton,
	IonButtons,
	IonHeader,
	IonPage,
	IonSkeletonText,
	IonTitle,
	useIonRouter,
} from "@ionic/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { type SetOptional } from "type-fest";
import { FriendCheckFilledCard } from "@/components/FriendCheck/FilledCard";
import { Content } from "@/components/Global/Content";
import { Toolbar } from "@/components/Global/Toolbar";
import { backendService } from "@/lib/backend";
import { type FriendCheckAnswerPair, type PatchFriendCheckSeenDto } from "@/lib/backend/api";
import { extractionWrapper, useExtractedMutation } from "@/lib/backend/utils";
import { getAnswer } from "@/lib/functions/answer";
import { usePreferredHaptics } from "@/lib/hooks/usePreferredHaptics";
import { arrowLeftIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { Routes } from "@/lib/router";

export function FriendCheckSubmission() {
	const { t, i18n } = useTranslation();
	const router = useIonRouter();
	const PreferredHaptics = usePreferredHaptics();
	const searchParams = Routes.FriendCheckSubmission.useParams();
	const queryClient = useQueryClient();

	function navigateBack() {
		if (router.routeInfo.pathname !== Routes.FriendCheckSubmission()) {
			return;
		}

		return router.canGoBack() ? router.goBack() : router.push(Routes.FriendCheck());
	}

	useEffect(() => {
		if (!searchParams.id) {
			navigateBack();
		}
	}, [searchParams.id]);

	const id = searchParams.id!;

	const queryFriendCheck = useQuery({
		queryKey: ["friend-check", id] as const,
		queryFn: (context) =>
			extractionWrapper(
				backendService.friendcheck.friendcheckControllerGetSubmission(context.queryKey[1], {
					signal: context.signal,
				}),
			),
		enabled: !!id,
	});

	useEffect(() => {
		if (!queryFriendCheck.isLoading && !queryFriendCheck.data) {
			navigateBack();
		}
	}, [queryFriendCheck.isLoading, queryFriendCheck.data]);

	const friendCheck = queryFriendCheck.data;

	const mutation = useExtractedMutation({
		mutationKey: ["friend-check", "submission", "seen"],
		fn: ({ friendCheckId, seen = true }: SetOptional<PatchFriendCheckSeenDto, "seen">) =>
			backendService.friendcheck.friendcheckControllerMarkFriendCheckSeen({
				friendCheckId,
				seen,
			}),
		onSuccess: () => queryClient.refetchQueries({ queryKey: ["friend-check"] }),
	});

	useEffect(() => {
		if (!friendCheck || !!friendCheck.seen) {
			return;
		}

		mutation.mutate({ friendCheckId: friendCheck.id });
	}, [friendCheck]);

	function flattenQuestions(
		question: FriendCheckAnswerPair | null | undefined,
	): FriendCheckAnswerPair[] {
		if (!question) {
			return [];
		}

		return [question, ...flattenQuestions(question.followUpQuestion)];
	}

	const questions = friendCheck?.questions.flatMap((question) => [
		question,
		...flattenQuestions(question.followUpQuestion),
	]);

	if (!id) {
		return <></>;
	}

	return (
		<IonPage>
			<IonHeader>
				<Toolbar className="ion-p-2 plt-desktop:sm:ion-px-[max(calc(calc(100vw-640px)/2),theme(spacing.4))]">
					<IonTitle className="text-base font-semibold text-brown-700">
						{t("friend-check.name")}
					</IonTitle>
					<IonButtons slot="secondary" onClick={() => PreferredHaptics.impact()}>
						<IonBackButton
							defaultHref={Routes.FriendCheck()}
							color="light"
							className="touch-target min-h-0 part-icon:m-0 part-icon:size-5 part-native:size-10 part-native:min-h-0 part-native:rounded-full part-native:bg-brown-300 part-native:p-1 part-native:text-xs part-native:text-brown-600"
							icon={arrowLeftIcon}
							text=""
						/>
					</IonButtons>
				</Toolbar>
			</IonHeader>

			<Content>
				{queryFriendCheck.isLoading ? (
					<>
						<IonSkeletonText animated className="min-h-[4.375rem] w-full rounded-2xl" />
						{Array.from({ length: 3 }).map((_, index) => (
							<IonSkeletonText
								key={index}
								animated
								data-index={index}
								className="h-64 w-full rounded-2xl data-[index='1']:h-56 data-[index='2']:h-48"
							/>
						))}
					</>
				) : (
					<>
						{!!friendCheck && <FriendCheckFilledCard data={friendCheck} />}
						{questions?.map((question) => (
							<div key={question.question} className="flex flex-col gap-4 rounded-2xl bg-white p-3">
								<strong className="text-pretty text-base font-semibold text-brown-600">
									{question.question}
								</strong>
								<p
									data-ph-no-capture
									className="rounded-2xl border-2 border-brown-200 bg-brown-100 p-4 text-base font-medium text-brown-600"
								>
									{getAnswer(question.answer, { language: i18n.language })}
								</p>
							</div>
						))}
					</>
				)}
			</Content>
		</IonPage>
	);
}
