import { IonLabel, IonList, IonListHeader } from "@ionic/react";
import { cx } from "@/lib/style/cva.config";

export function SettingsSection({
	header,
	...props
}: { header?: React.ReactNode } & React.ComponentPropsWithoutRef<"div">) {
	return (
		<div {...props} className={cx("flex flex-col gap-2", props.className)}>
			{!!header && (
				<IonListHeader className="min-h-0 p-0 text-xl font-semibold ion-text-brown-700">
					<IonLabel className="m-0">{header}</IonLabel>
				</IonListHeader>
			)}
			<IonList inset className="!m-0 !rounded-2xl border border-brown-200">
				{props.children}
			</IonList>
		</div>
	);
}
