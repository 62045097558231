import { IonPage } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import { usePostHog } from "posthog-js/react";
import { z } from "zod";
import { AuthContent } from "@/components/Auth/Content";
import { AuthHeader } from "@/components/Auth/Header";
import { AuthPageHeaderTitle } from "@/components/Auth/PageHeaderTitle";
import { AuthSubmitButton } from "@/components/Auth/SubmitButton";
import { FormInput } from "@/components/Form/Input";
import { TitleIcon } from "@/components/TitleIcon";
import { backendService } from "@/lib/backend";
import { ContentType, type SignupDto } from "@/lib/backend/api";
import { useExtractedMutation } from "@/lib/backend/utils";
import { env } from "@/lib/env";
import { keyIcon } from "@/lib/icons/@heroicons/react/24/outline";
import { logger } from "@/lib/logger";
import { Routes } from "@/lib/router";
import { useSelectorAuthStore } from "@/lib/store/auth";
import { useAuthFlowStore } from "@/lib/store/auth-flow";
import { useZodForm } from "@/utils/hooks/react-form/useZodForm";

export function AuthSignUpPassword() {
	const posthog = usePostHog();
	const state = useAuthFlowStore();
	const authenticate = useSelectorAuthStore.use.authenticate();
	const { t } = useTranslation();

	const mutationSignUp = useExtractedMutation({
		mutationKey: ["auth", "sign-up"],
		fn: (variables: SignupDto) =>
			backendService.auth.authControllerSignup(variables, { type: ContentType.Json }),
	});

	const form = useZodForm({
		defaultValues: {
			email: state.email,
			verificationCode: state.verificationCode,
			password: state.password,
		},
		onSubmit: ({ value, formApi }) =>
			mutationSignUp.mutateAsync(value, {
				onSuccess: (data) => {
					if (!data) {
						throw new Error("Sign Up Failed", { cause: "No data" });
					}

					posthog.capture("User Sign Up Password", { success: true });
					authenticate(data);
					useAuthFlowStore.getState().reset();
				},
				onError: (error) => {
					posthog.capture("User Sign Up Password", { success: false });
					logger.error({ error }, "Sign Up Failed");

					formApi.setFieldMeta("password", (current) => ({
						...current,
						errorMap: {
							onSubmit: t("fields.password.errors.invalid"),
						},
					}));
				},
			}),
	});

	if (!state.verificationCode) {
		if (!state.email) {
			return <Redirect to={Routes.AuthSignUp()} />;
		}

		return <Redirect to={Routes.AuthSignUpVerify()} />;
	}

	return (
		<IonPage>
			<AuthHeader parts={{ backButton: { defaultHref: Routes.AuthSignUpVerify() } }} />
			<AuthContent>
				<TitleIcon icon={keyIcon} />

				<AuthPageHeaderTitle
					parts={{
						title: { children: t("page.auth.sign-up.steps.password.title") },
						subtitle: {
							children: t("page.auth.sign-up.steps.password.subtitle", {
								number: env.VITE_PASSWORD_MIN_LENGTH,
								requirement: env.VITE_PASSWORD_REQUIREMENTS,
							}),
						},
					}}
				>
					<ul className="ml-6 list-disc">
						<li>{t("fields.password.requirements.capital")}</li>
						<li>{t("fields.password.requirements.lowercase")}</li>
						<li>{t("fields.password.requirements.digit")}</li>
						<li>{t("fields.password.requirements.symbol")}</li>
					</ul>
				</AuthPageHeaderTitle>

				<form
					onSubmit={(event) => {
						event.preventDefault();
						event.stopPropagation();
						form.validateAllFields("change");
						void form.handleSubmit();
					}}
					className="flex flex-col gap-6"
				>
					<form.Field
						name="email"
						children={(field) => (
							<input
								type="email"
								name={field.name}
								autoComplete="email"
								readOnly
								className="invisible hidden"
								defaultValue={field.state.value}
							/>
						)}
					/>

					<form.Field
						name="verificationCode"
						children={(field) => (
							<input
								type="text"
								name={field.name}
								readOnly
								className="invisible hidden"
								defaultValue={field.state.value}
							/>
						)}
					/>

					<form.Field
						name="password"
						validators={{
							onSubmit: z
								.string()
								.min(
									env.VITE_PASSWORD_MIN_LENGTH,
									t("fields.password.errors.min", {
										number: env.VITE_PASSWORD_MIN_LENGTH,
									}),
								)
								.refine(
									(value) => {
										let requirementMet = 0;

										// Uppercase
										if (/[A-Z]/g.test(value)) {
											requirementMet++;
										}

										// Lowercase
										if (/[a-z]/g.test(value)) {
											requirementMet++;
										}

										// Number
										if (/[0-9]/g.test(value)) {
											requirementMet++;
										}

										// Symbol
										if (/[~`¿¡!#$%^&*€£@+÷=\-[\]\\';,/{}()|\\":<>?._]/.test(value)) {
											requirementMet++;
										}

										return requirementMet >= env.VITE_PASSWORD_REQUIREMENTS;
									},
									{
										message: t("fields.password.errors.requirement", {
											requirement: env.VITE_PASSWORD_REQUIREMENTS,
										}),
									},
								),
						}}
						children={(field) => (
							<FormInput
								field={field}
								type="password"
								autofocusOnIonViewDidEnter
								label={t("fields.password.label")}
								autocomplete="new-password"
								placeholder={t("fields.password.placeholder")}
							/>
						)}
					/>

					<form.Subscribe
						selector={(state) => [state.canSubmit, state.isSubmitting]}
						children={([canSubmit, isSubmitting]) => (
							<AuthSubmitButton disabled={!canSubmit} isSubmitting={isSubmitting}>
								{t("page.auth.sign-up.steps.password.action.primary")}
							</AuthSubmitButton>
						)}
					/>
				</form>
			</AuthContent>
		</IonPage>
	);
}
