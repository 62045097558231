import { IonModal } from "@ionic/react";
import { IonButtons, IonFooter, IonIcon, IonText } from "@ionic/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { usePostHog } from "posthog-js/react";
import { Button } from "@/components/Global/Button";
import { Toolbar } from "@/components/Global/Toolbar";
import { arrowRightIcon } from "@/lib/icons/@heroicons/react/20/solid";
import { useMyData } from "@/lib/query/functions/user/data";
import { useSelectorModalStore } from "@/lib/store/modal";

export function ModalReflectionExplainerGeneral() {
	const { t } = useTranslation();
	const posthog = usePostHog();
	const modalRef = useRef<HTMLIonModalElement>(null);
	const modal = useSelectorModalStore.use.reflectionExplainerGeneral();
	const present = useSelectorModalStore.use.present();
	const dismiss = useSelectorModalStore.use.dismiss();
	const [, { mutation }] = useMyData();

	return (
		<IonModal
			ref={modalRef}
			isOpen={modal.isOpen}
			onWillPresent={() => present("reflectionExplainerGeneral")}
			onWillDismiss={() => dismiss("reflectionExplainerGeneral", { onCompleted: null })}
			initialBreakpoint={1}
			breakpoints={[0, 1]}
			className="ion-h-auto"
		>
			<div className="ion-padding flex flex-col gap-6 pt-12">
				<IonText className="text-2xl font-bold text-brown-700">
					{t("modal.reflection.explainer.general.title")}
				</IonText>

				<ol className="flex flex-col gap-8">
					{[
						{
							title: t("modal.reflection.explainer.general.point-1.title"),
							text: t("modal.reflection.explainer.general.point-1.text"),
						},
						{
							title: t("modal.reflection.explainer.general.point-2.title"),
							text: t("modal.reflection.explainer.general.point-2.text"),
						},
						{
							title: t("modal.reflection.explainer.general.point-3.title"),
							text: t("modal.reflection.explainer.general.point-3.text"),
						},
					].map((value, index) => (
						<li key={index + 1} className="flex items-start gap-3">
							<IonText className="grid size-8 shrink-0 select-none place-content-center rounded-full bg-white text-center text-lg font-bold text-brown-500">
								{index + 1}
							</IonText>
							<div className="flex flex-col">
								<IonText className="text-lg font-bold text-brown-600">{value.title}</IonText>
								<IonText className="text-base font-normal text-brown-700">{value.text}</IonText>
							</div>
						</li>
					))}
				</ol>
			</div>

			<IonFooter className="ion-no-border">
				<Toolbar className="ion-bg-brown-200 ion-p-4">
					<IonButtons slot="primary">
						<Button
							className="ion-bg-brown-100 ion-text-brown-700 !ion-rounded-2xl !ion-px-7 !ion-py-3 part-native:text-lg part-native:font-bold"
							onClick={() => {
								mutation.mutate(
									(draft) => void (draft.modal.reflectionExplainerGeneral.seen = true),
								);
								posthog.capture("Modal Reflection Explainer General Finished");
								modal.onCompleted?.();
								modalRef.current?.dismiss();
							}}
						>
							{t("modal.reflection.explainer.general.next")}
							<IonIcon slot="end" icon={arrowRightIcon} className="size-6" />
						</Button>
					</IonButtons>
				</Toolbar>
			</IonFooter>
		</IonModal>
	);
}
