import { omit } from "lodash-es";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { type FriendCheckSubmissionResponse, type SubmitFriendcheckDto } from "@/lib/backend/api";
import { Capacitor } from "@/lib/capacitor";
import { debugValue } from "@/lib/env/utils";
import { createSelectors } from "@/lib/store/utils/createSelectors";

export type FriendCheckState = {
	submitted: boolean;
	result?: FriendCheckSubmissionResponse;
	friendId: string;
	friendHash: string;
	acceptsTerms: boolean;
	acceptsEmail: boolean;
	questions: Record<string, string>;
} & Omit<SubmitFriendcheckDto, "questions">;

export type FriendCheckActions = { reset: VoidFunction };

const initialState: FriendCheckState = {
	submitted: false,
	result: undefined,
	friendId: "",
	friendHash: "",
	name: "",
	questions: {},
	relation: "",
	acceptsTerms: false,
	email: "",
	acceptsEmail: false,
};

export const useFriendCheckStore = create<FriendCheckState & FriendCheckActions>()(
	devtools(
		immer((set) => ({
			...initialState,

			reset: () => set(initialState),
		})),
		{
			enabled: debugValue("store") && Capacitor.Platform === "web",
		},
	),
);

export const useSelectorFriendCheckStore = createSelectors(useFriendCheckStore);

export const friendCheckSelectors = {
	state: (state: FriendCheckState) => omit(state, "reset"),
};
