import { useIonRouter } from "@ionic/react";
import { z } from "zod";
import { Routes } from "@/lib/router";

/** Based on https://www.flightcontrol.dev/blog/fix-nextjs-routing-to-have-full-type-safety */

type RouteBuilder<Params extends z.ZodSchema> = {
	(p?: z.input<Params>): string;
	parse: (input: z.input<Params>) => z.output<Params>;
	useParams: () => z.output<Params>;
	params: z.output<Params>;
};

const empty: z.ZodSchema = z.object({});

export function makeRoute<Params extends z.ZodSchema>(
	path: string,
	paramsSchema: Params = empty as Params,
): RouteBuilder<Params> {
	const routeBuilder: RouteBuilder<Params> = (params) => {
		const searchString = params && new URLSearchParams(params).toString();
		return [path, searchString ? `?${searchString}` : ""].join("");
	};

	routeBuilder.parse = function parse(args: z.input<Params>): z.output<Params> {
		const res = paramsSchema.safeParse(args);
		if (!res.success) {
			const routeName =
				Object.entries(Routes).find(([, route]) => (route as unknown) === routeBuilder)?.[0] ||
				"(unknown route)";
			throw new Error(`Invalid route params for route ${routeName}: ${res.error.message}`);
		}
		return res.data;
	};

	routeBuilder.useParams = function useParams(): z.output<Params> {
		const res = paramsSchema.safeParse(
			convertURLSearchParamsToObject(new URLSearchParams(useIonRouter().routeInfo.search)),
		);
		if (!res.success) {
			const routeName =
				Object.entries(Routes).find(([, route]) => (route as unknown) === routeBuilder)?.[0] ||
				"(unknown route)";
			throw new Error(`Invalid route params for route ${routeName}: ${res.error.message}`);
		}
		return res.data;
	};

	// set the type
	routeBuilder.params = undefined as z.output<Params>;
	// set the runtime getter
	Object.defineProperty(routeBuilder, "params", {
		get() {
			throw new Error(
				"Routes.[route].params is only for type usage, not runtime. Use it like `typeof Routes.[routes].params`",
			);
		},
	});

	return routeBuilder;
}

export function convertURLSearchParamsToObject(
	params: Readonly<URLSearchParams> | null,
): Record<string, string | string[]> {
	if (!params) {
		return {};
	}

	const obj: Record<string, string | string[]> = {};
	for (const [key, value] of params.entries()) {
		if (params.getAll(key).length > 1) {
			obj[key] = params.getAll(key);
		} else {
			obj[key] = value;
		}
	}
	return obj;
}
