import { Capacitor as CapacitorCore } from "@capacitor/core";

export const Capacitor = (function () {
	function getPlatform() {
		return CapacitorCore.getPlatform() as Platform;
	}

	return {
		...CapacitorCore,
		getPlatform,
		Platform: getPlatform(),
	};
})();

export type * from "@capacitor/core";
