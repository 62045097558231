import { IonCard } from "@ionic/react";
import { forwardRef } from "react";
import { cx } from "@/lib/style/cva.config";

export const ReflectionCard = forwardRef<
	HTMLIonCardElement,
	Omit<React.ComponentProps<typeof IonCard>, "draggable" | "onDrag" | "onDragEnd">
>(function ReflectionCard(props, ref) {
	return (
		<IonCard
			ref={ref}
			{...props}
			className={cx(
				"m-0 flex flex-col gap-1 rounded-3xl border border-brown-200 p-4 text-base font-semibold shadow-none ion-bg-brown-100 ion-text-brown-700 part-native:flex part-native:flex-col part-native:gap-3 part-native:bg-transparent",
				!!props.onClick && "ion-activatable cursor-pointer",
				props.className,
			)}
		>
			{props.children}
		</IonCard>
	);
});
