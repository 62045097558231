import { useMemo } from "react";
import { type QueryFunctionContext, type UseQueryOptions, useQuery } from "@tanstack/react-query";
import { backendService } from "@/lib/backend";
import { extractionWrapper } from "@/lib/backend/utils";
import { authSelectors, useAuthStore } from "@/lib/store/auth";

export const queryKey_me = ["user", "me"] as const;
export const queryFn_me = (context: QueryFunctionContext<typeof queryKey_me>) =>
	extractionWrapper(backendService.user.usersControllerMe({ signal: context.signal }));

type QueryOptions = UseQueryOptions<
	Awaited<ReturnType<typeof queryFn_me>>,
	Error,
	Awaited<ReturnType<typeof queryFn_me>>,
	typeof queryKey_me
>;

export const query_me = {
	queryFn: queryFn_me,
	queryKey: queryKey_me,
} satisfies QueryOptions;

export function useMe(options?: Partial<QueryOptions>) {
	const isAuthenticated = useAuthStore(authSelectors.isAuthenticated);

	const query = useQuery({ ...query_me, enabled: isAuthenticated, ...options });
	const me = query.data;

	return useMemo(() => [me, query] as const, [me, query.status, query.dataUpdatedAt, query.data]);
}
