import { type User } from "@/lib/backend/api";

export function getAvatarUrl(user?: Pick<User, "updatedAt" | "avatarURL">) {
	if (!user || !user.avatarURL) {
		return;
	}

	const url = new URL(user.avatarURL);
	url.searchParams.set("t", String(new Date(user.updatedAt).getTime()));

	return url.toString();
}
